import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/arrow.svg'
import _imports_1 from '@/assets/images/email-sent.svg'
import _imports_2 from '@/assets/images/backgroundGraphics.svg'


const _hoisted_1 = { class: "relative flex items-center min-h-screen justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16" }
const _hoisted_2 = {
  class: "relative px-6 mt-20 py-3 pb-6 text-dark-type-4 lg:w-8/12 w-9/12 mx-auto z-10 bg-white rounded-lg",
  style: {"margin-top":"80px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "flex items-start w-full p-3 mx-auto my-5 bg-red-200 rounded-md"
}
const _hoisted_5 = { class: "text-sm font-md text-red-500" }
const _hoisted_6 = { class: "mt-6" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 1,
  class: "ml-2",
  src: _imports_0
}
const _hoisted_11 = {
  key: 1,
  class: "w-full max-w-md"
}
const _hoisted_12 = {
  key: 1,
  class: "hidden lg:flex absolute bottom-0 z-0"
}

import { ref } from 'vue';
import { required, email } from '@vuelidate/validators';
import { extractErrorMessage } from '../../utils/helper';
import useVuelidate from '@vuelidate/core';
import Spinner from '@/components/layout/Spinner.vue';
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPassword',
  setup(__props) {

const toast = useToast()
const validations = {
  form: {
    email: {
      required,
      email
    }
  }
}
const linkSent = ref(false);
const errorMessage = ref('');
const form = ref({
  email: '',
  type: 'user',
  app: 'partners-portal'
});
const processing = ref(false);
const v$ = useVuelidate(validations, {form})

const goBack = () => {
  router.go(-1);
}
const sendPasswordResetEmail = () => {
  v$.value.form.$touch();

  if (processing.value || v$.value.form.$errors.length) {
    return;
  }

  processing.value = true;
  errorMessage.value = '';
  const payload = { ...form.value };

  axios
    .post('v1/password', payload)
    .then(async () => {
      linkSent.value = true;
    })
    .catch((err) => {
      linkSent.value = false;
      toast.error(
        extractErrorMessage(
          err,
          null,
          'Oops! An error occurred, please try again.'
        ), { position: 'top-right' }
      );
    })
    .finally(() => (processing.value = false));
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (!linkSent.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: goBack,
          class: "block w-20 top-0 left-6 py-2 absolute text-sm mx-auto mt-6 font-medium text-center text-white bg-grays-black-7 rounded bg-active"
        }, "Back"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!linkSent.value)
        ? (_openBlock(), _createElementBlock("form", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-sh-grey-900 font-bold text-lg lg:text-2xl text-center" }, " Forgot your password? ", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mt-1 text-xs font-medium text-center text-dark-type-4" }, " Don’t worry, it happens to the best of us. ", -1)),
            (errorMessage.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(errorMessage.value), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("label", { class: "text-grays-black-5 font-medium text-sm" }, "Email address", -1)),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).form.email.$model) = $event)),
                  class: _normalizeClass([
                  _unref(v$).form.email.$dirty && _unref(v$).form.email.$error
                    ? 'ring-red-500'
                    : 'ring-sh-grey-300'
                , "py-3 px-5 border-none placeholder:text-grays-black-7 outline-none w-full ring-1 text-sm rounded-md placeholder-opacity-50"]),
                  type: "email",
                  id: "email",
                  placeholder: "sample@mail.com"
                }, null, 2), [
                  [_vModelText, _unref(v$).form.email.$model]
                ]),
                (_unref(v$).form.email.$dirty && _unref(v$).form.email.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Invalid email"))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (sendPasswordResetEmail()), ["prevent"])),
              type: "button",
              class: _normalizeClass(["border-none outline-none py-3 rounded-md text-sm flex justify-center items-center w-full mt-8", 
              _unref(v$).form.$invalid || processing.value
                ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7'
                : 'bg-sh-green-500 font-medium'
            ]),
              disabled: _unref(v$).form.$invalid || processing.value
            }, [
              _createTextVNode(_toDisplayString(processing.value ? 'Processing...' : 'Recover Password') + " ", 1),
              (processing.value)
                ? (_openBlock(), _createBlock(Spinner, {
                    key: 0,
                    class: "w-5 ml-2 mx-0"
                  }))
                : _createCommentVNode("", true),
              (!processing.value)
                ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                : _createCommentVNode("", true)
            ], 10, _hoisted_9)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _cache[6] || (_cache[6] = _createElementVNode("img", {
              src: _imports_1,
              class: "block mx-auto text-center",
              alt: "Check your email"
            }, null, -1)),
            _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "mt-2 text-2xl font-bold text-center text-dark-type-3" }, " Check your email ", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mt-1 text-sm font-medium text-center text-dark-type-4" }, " Check your email for password reset instructions ", -1)),
            _createVNode(_component_router_link, {
              to: "/login",
              class: "block w-56 py-3 mx-auto mt-6 font-bold text-center text-white bg-sh-green-500 rounded bg-active"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Back to home")
              ])),
              _: 1
            })
          ]))
    ]),
    (!linkSent.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[9] || (_cache[9] = [
          _createElementVNode("img", {
            src: _imports_2,
            class: "w-auto"
          }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})