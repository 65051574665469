import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/arrow.svg'
import _imports_1 from '@/assets/images/faqs/question_mark.svg'


const _hoisted_1 = { class: "space-y-5" }
const _hoisted_2 = { class: "flex flex-col space-y-5" }
const _hoisted_3 = { class: "flex flex-col space-y-2" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_5 = { class: "flex flex-col space-y-2 mt-6 relative" }
const _hoisted_6 = ["type"]
const _hoisted_7 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_8 = { class: "flex justify-end mt-4" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 1,
  class: "ml-2",
  src: _imports_0
}
const _hoisted_11 = { class: "flex justify-center items-center space-x-2 text-sm md:text-base font-medium" }

import { ref } from 'vue';
// import { setZohoUser } from '@/utils/zoho';
import { useStore } from 'vuex';
import { required, email } from '@vuelidate/validators';
import LoginMixin from '@/mixins/LoginMixin';
import { LoginResponse } from '@/models/login-response.model';
import { AxiosResponse } from 'axios';
import { extractErrorMessage } from '@/utils/helper';
import Spinner from '@/components/layout/Spinner.vue';
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import useVuelidate from "@vuelidate/core";
import { useRoute } from 'vue-router';
import { setPosthogIdentity } from '@/composables/analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'EmailLogin',
  emits: ['goToSignUp'],
  setup(__props, { emit: __emit }) {

const validations = {
  form: {
    email: { required, email },
    password: { required }
  }
}
const route = useRoute()
const store = useStore()
const toast = useToast()
const emit = __emit
const form = ref({
  email: '',
  password: ''
});
const showPassword = ref(false);
const processing = ref(false);
const v$ = useVuelidate(validations, {form})
// mixins: [LoginMixin],

const toggleShow = () => {
  showPassword.value = !showPassword.value;
}
// ...mapActions('auth', ['setSessionData']),
const submitForm = async () => {
  v$.value.form.$touch();
  if (processing.value || v$.value.form.$errors.length) {
    return;
  }
  const payload = {
    email: form.value.email,
    password: form.value.password
  };
  try {
    processing.value = true;
    const loginResponse: AxiosResponse<LoginResponse> =
      await axios.post('v1/login', payload);
    if (loginResponse?.data) {
      await store.dispatch('auth/authSuccess', loginResponse.data);
      // window.$zoho.salesiq.reset();
      // setZohoUser(loginResponse?.data);
      setPosthogIdentity(loginResponse?.data)
      const redirect: any = route.query.redirect;
      if (redirect) {
        router.push({ path: redirect });
      } else {
        router.push({
          name: 'dashboard',
          query: { ...route.query }
        });
      }
    } else {
      toast.error('Login failed!', { position: 'top-right' });
    }
  } catch (e) {
    const errorMessage = extractErrorMessage(
      e,
      null,
      'Oops! An error occurred, please try again.'
    );
    toast.error(errorMessage, { position: 'top-right'});
  } finally {
    processing.value = false;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "text-grays-black-5 font-medium text-sm" }, "Email address", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).form.email.$model) = $event)),
          type: "email",
          class: _normalizeClass([
            _unref(v$).form.email.$dirty && _unref(v$).form.email.$error
              ? 'ring-red-500'
              : 'ring-sh-grey-300'
          , "py-3 px-5 border-none placeholder:text-grays-black-7 outline-none ring-1 text-sm rounded-md placeholder-opacity-50"]),
          placeholder: "Type your email address"
        }, null, 2), [
          [_vModelText, _unref(v$).form.email.$model]
        ]),
        (_unref(v$).form.email.$dirty && _unref(v$).form.email.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Invalid email"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "text-grays-black-5 font-medium text-sm" }, "Password", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).form.password.$model) = $event)),
          type: showPassword.value ? 'text' : 'password',
          class: _normalizeClass([
            _unref(v$).form.password.$dirty && _unref(v$).form.password.$error
              ? 'ring-red-500'
              : 'ring-sh-grey-300'
          , "py-3 px-5 border-none placeholder:text-grays-black-7 outline-none ring-1 text-sm rounded-md placeholder-opacity-50"]),
          placeholder: "Type your password"
        }, null, 10, _hoisted_6), [
          [_vModelDynamic, _unref(v$).form.password.$model]
        ]),
        (_unref(v$).form.password.$dirty && _unref(v$).form.password.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Invalid password"))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          onClick: toggleShow,
          class: _normalizeClass(["absolute top-7 right-3 cursor-pointer text-sm font-medium", [showPassword.value ? 'text-green-500' : 'text-purple-dark']])
        }, _toDisplayString(showPassword.value ? 'Hide' : 'Show'), 3)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_router_link, {
          to: { name: 'forgotPassword' },
          class: "text-grays-black-3 text-end text-xs font-medium"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Forgot Password ?")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("button", {
        class: _normalizeClass([
          _unref(v$).form.password.$invalid || _unref(v$).form.email.$invalid || processing.value
            ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7'
            : 'bg-sh-green-500 font-medium'
        , "flex justify-center items-center w-full p-3 rounded-md border-none outline-none text-sm mt-8"]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (submitForm())),
        type: "button",
        disabled: 
          _unref(v$).form.email.$invalid || _unref(v$).form.password.$invalid || processing.value
        
      }, [
        _createTextVNode(_toDisplayString(processing.value ? 'Loading' : 'Login') + " ", 1),
        (processing.value)
          ? (_openBlock(), _createBlock(Spinner, {
              key: 0,
              class: "!ml-2 !w-fit !mx-0"
            }))
          : _createCommentVNode("", true),
        (!processing.value)
          ? (_openBlock(), _createElementBlock("img", _hoisted_10))
          : _createCommentVNode("", true)
      ], 10, _hoisted_9),
      _createElementVNode("div", _hoisted_11, [
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-grays-black-3" }, "Do you want to be a partner?", -1)),
        _createElementVNode("p", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('goToSignUp'))),
          class: "text-indigo-600 text-sm md:text-base cursor-pointer"
        }, " Join now ")
      ]),
      _createVNode(_component_router_link, {
        to: "/faq",
        class: "mt-4 w-fit mx-auto text-sm py-2 px-3 flex items-center gap-2 text-[#0DAC5C] rounded-lg font-medium"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1),
          _createTextVNode(" Go to FAQ ")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})