import { ref, computed } from "vue"
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useStore } from 'vuex';
import { useFetchMembers } from "./fetch";
import { sendAnalytics } from "@/composables/analytics";

const toast = useToast()

const { fetchMembers } = useFetchMembers()
const showModal = ref(false)
const loading = ref(false)
const loading_roles = ref(false)
const roles = ref([] as Record<string, any>[])
const obj = {
  email: ref(''),
  selected_roles: ref([] as number[]),
  isEdit: ref(false),
  memberObj: ref(null as Record<string, any>|null)
}

const clearObj = () => {
  obj.email.value = ''
  obj.selected_roles.value = []
  obj.isEdit.value = false
  obj.memberObj.value = null
}

const getRoleLabelById = (role_id:number) => {
  return roles.value?.filter(el => el?.id === role_id)?.[0]?.name
}

const handleSelectRole = (role_id:number) => {
  if (!obj.selected_roles.value?.includes(role_id)) {
    obj.selected_roles.value?.push(role_id)
  } else {
    const index = obj.selected_roles.value?.indexOf(role_id)
    obj.selected_roles.value.splice(index, 1);
  }
}

export const useCreateMember = () => {
  // const store = useStore()
  // const partnerContext:any = computed(() => store.getters['auth/activeContext'])

  const fetchRoles = async () => {
    loading_roles.value = true
    try {
      const res = await axios.get(`/v1/acl/roles?group=partner`)
      if (res?.status === 200) {
        roles.value = res?.data?.length ? res.data : []
      }
    } catch (error:any) {
      toast.error(error?.response?.data.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading_roles.value = false
    }
  }

  const inviteMember = async (partnerId:number) => {
    const payload = {
      owner_type: "partner",
      owner_id: String(partnerId),
      member_email: obj.email.value,
      member_roles: obj.selected_roles.value
    }
    loading.value = true
    try {
      const res = await axios.post(`/v1/account_members`, payload)
      if (res?.status === 200 || res.status === 201) {
        toast.success(res?.data?.message || 'Invite sent Successfully', { position: 'top-right' });
        const roleObj:Record<string, any> = {}
        for (const el of obj.selected_roles.value) {
          roleObj[getRoleLabelById(el)] = true
        }
        sendAnalytics('Partner Invited A New Member', {
          email: obj.email.value,
          ...roleObj
        })
        fetchMembers(partnerId)
        showModal.value = false
      }
    } catch (error:any) {
      toast.error(error?.response?.data?.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading.value = false
    }
  }

  const initUpdateMemberRole = (member_data: Record<string, any>) => {
    obj.memberObj.value = member_data
    obj.selected_roles.value = [...member_data?.member_roles]
    obj.isEdit.value = true
    showModal.value = true
  }

  const updateMemberRole = async (partnerId:number) => {
    const payload = {
      member_roles: obj.selected_roles.value
    }
    loading.value = true
    try {
      const res = await axios.patch(`/v1/account_members/${obj.memberObj.value?.id}/roles`, payload)
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.data?.message || 'Roles updated successful', { position: 'top-right' });
        const roleObj:Record<string, any> = {}
        for (const el of obj.selected_roles.value) {
          roleObj[getRoleLabelById(el)] = true
        }
        sendAnalytics('Partner Updated A Members Permission', {
          email: obj.memberObj.value?.member_email,
          ...roleObj
        })
        showModal.value = false
        fetchMembers(partnerId)
      }
    } catch (error:any) {
      toast.error(error?.response?.data?.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading.value = false
    }
  }

  return { showModal, fetchRoles, loading, loading_roles, roles, ...obj, clearObj, handleSelectRole, inviteMember, initUpdateMemberRole, updateMemberRole }
}
