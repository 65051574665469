import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/arrow.svg'


const _hoisted_1 = { class: "space-y-3 lg:space-y-7" }
const _hoisted_2 = { class: "lg:flex justify-between space-y-3 lg:space-y-0 lg:space-x-10 items-center" }
const _hoisted_3 = { class: "space-y-2 w-full" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_5 = { class: "space-y-2 w-full" }
const _hoisted_6 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_7 = { class: "lg:flex justify-between space-y-3 lg:space-y-0 lg:space-x-10 items-center" }
const _hoisted_8 = { class: "space-y-2 w-full" }
const _hoisted_9 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_10 = { class: "space-y-2 w-full" }
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_13 = { class: "lg:flex justify-between space-y-3 lg:space-y-0 lg:space-x-10 items-center" }
const _hoisted_14 = { class: "space-y-2 w-full lg:w-6/12 pr-1" }
const _hoisted_15 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_16 = { class: "space-y-2 w-full lg:w-6/12 pr-1" }
const _hoisted_17 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_18 = { class: "flex justify-start space-x-10 items-start" }
const _hoisted_19 = { class: "space-y-2 w-full lg:w-6/12 lg:pr-6 relative" }
const _hoisted_20 = ["value"]
const _hoisted_21 = {
  key: 0,
  class: "text-sm text-red-500 font-light"
}
const _hoisted_22 = { class: "flex justify-end items-end space-x-5" }
const _hoisted_23 = ["disabled"]

import { ref, Ref, watch, computed } from 'vue';
import Datepicker from 'vue3-datepicker';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import countryCodeEmoji from 'country-code-emoji';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { useStore } from 'vuex';
import { extractErrorMessage } from '@/utils/helper';
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import ordinaryAutoComplete from '@/components/core/ordinaryAutoComplete.vue'
import {formatDate} from '@/composables/utils'
import { resetPosthogIdentity, sendAnalytics } from '@/composables/analytics';
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyInformation',
  emits: ['companySignUpSuccessful'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const route = useRoute()
const toast = useToast()
const store = useStore()
const validations = {
  form: {
    mode: { required },
    experience_start_date: { required },
    company_name: { required },
    rc_number: { required },
    company_address: { required },
    business_type: { required },
    company_email: { required, email },
    company_phone: { required }
  }
}
const form = ref({
  mode: route.query.type,
  experience_start_date: new Date(),
  company_name: '',
  rc_number: '',
  company_address: '',
  business_type: '',
  company_email: '',
  company_phone: '',
  country: ''
});
const countries = ref([]) as Ref<any[]>
const isPhoneValid = ref(false);
const businessOptions = [
  'Business Name',
  'Company',
  'Incorporated Trustee',
  'Limited Partnership',
  'Limited Liability Partnership'
];
const processing = ref(false);
const v$ = useVuelidate(validations, {form})

const userSessionData:any = computed(() => store.getters['auth/userSessionData'])
const user:any = computed(() => store.getters['auth/user'])
const countrySelectDisabled = computed(() => {
  return countries.value.length <= 1;
})

watch(() => form.value.company_phone, () => {
  validatePhoneNumber()
})

watch(countries, () => {
  setDefaultCountry()
})

const setDefaultCountry = () => {
  const code =
    countries.value && countries.value.length
      ? (countries.value[0] as any).code
      : null;
  if (code) {
    form.value.country = code;
  }
}
const fetchCountries = async () => {
  const response = await axios.get(`v1/countries`);
  countries.value = response.data?.data || [];
}
const validatePhoneNumber = () => {
  isPhoneValid.value = isValidPhoneNumber(
    form.value.company_phone.toString(),
    form.value.country as CountryCode
  );
}
const countryCodeToEmoji = (code: string) => {
  return countryCodeEmoji(code);
}
const saveForm = async () => {
  v$.value.form.$touch();
  if (processing.value || v$.value.form.$errors.length) {
    return;
  }
  processing.value = true;
  try {
    const payload = {
      ...form.value,
      mode: 'business'
    };
    const response = await axios.post('/v1/partners', { ...payload });
    if (response.data) {
      await store.dispatch('auth/refreshActiveContext', user.value.id);
      emit('companySignUpSuccessful');
      sendAnalytics('Partner Provided Business Information', {
        started_transport_business_on: moment(form.value.experience_start_date).format('ll')
      })
    }
  } catch (err) {
    const errorMessage = extractErrorMessage(
      err,
      null,
      'Oops! An error occurred, please try again.'
    );
    toast.error(errorMessage, { position: 'top-right' });
  } finally {
    processing.value = false;
  }
}
const logout = () => {
  resetPosthogIdentity()
  // window.$zoho.salesiq.reset();
  localStorage.clear();
  router.push('/login');
  router.go(0);
}

setDefaultCountry();
fetchCountries();

return (_ctx: any,_cache: any) => {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Company name", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).form.company_name.$model) = $event)),
          type: "text",
          class: "text-xs border-none outline-none w-full rounded-md p-3 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-gray-300",
          placeholder: "Enter your company name"
        }, null, 512), [
          [_vModelText, _unref(v$).form.company_name.$model]
        ]),
        (
            _unref(v$).form.company_name.$dirty &&
            _unref(v$).form.company_name.required.$invalid
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Please provide your company name "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[10] || (_cache[10] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Company RC number", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).form.rc_number.$model) = $event)),
          class: "text-xs border-none outline-none w-full rounded-md p-3 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-gray-300",
          placeholder: "Enter your RC number"
        }, null, 512), [
          [_vModelText, _unref(v$).form.rc_number.$model]
        ]),
        (_unref(v$).form.rc_number.$dirty && _unref(v$).form.rc_number.required.$invalid)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, " This field is required "))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Company address", -1)),
        _createVNode(ordinaryAutoComplete, {
          placeHolder: "Enter your work addresss",
          inputValue: _unref(v$).form.company_address.$model,
          onAutoCompleteAddress: _cache[2] || (_cache[2] = (val) => _unref(v$).form.company_address.$model = val)
        }, null, 8, ["inputValue"]),
        (
            _unref(v$).form.company_address.$dirty &&
            _unref(v$).form.company_address.required.$invalid
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, " This field is required "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Type of Incorporated Businesses", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).form.business_type.$model) = $event)),
          class: "text-xs border-none outline-none w-full rounded-md p-3 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-gray-300"
        }, [
          _cache[12] || (_cache[12] = _createElementVNode("option", { hidden: "" }, "Select your business type", -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(businessOptions, (option, index) => {
            return _createElementVNode("option", {
              value: option,
              key: index
            }, _toDisplayString(option), 9, _hoisted_11)
          }), 64))
        ], 512), [
          [_vModelSelect, _unref(v$).form.business_type.$model]
        ]),
        (
            _unref(v$).form.business_type.$dirty &&
            _unref(v$).form.business_type.required.$invalid
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, " This field is required "))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "When did you start your transport business ?", -1)),
        _createVNode(_component_v_date_picker, {
          modelValue: (_unref(v$).form.experience_start_date as any).$model,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (((_unref(v$).form.experience_start_date as any).$model) = $event))
        }, {
          default: _withCtx(({ inputEvents }) => [
            _createElementVNode("div", _mergeProps(_toHandlers(inputEvents, true), {
              class: ["w-full ring-1 ring-gray-300 rounded-md outline-none text-xs py-3 px-4", [(_unref(v$).form.experience_start_date as any).$model ? '' : 'text-gray-500/25']]
            }), _toDisplayString((_unref(v$).form.experience_start_date as any).$model ? _unref(formatDate)((_unref(v$).form.experience_start_date as any).$model) : 'Choose a date'), 17)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        (
            _unref(v$).form.experience_start_date.$dirty &&
            _unref(v$).form.experience_start_date.required.$invalid
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_15, " This field is required "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[15] || (_cache[15] = _createElementVNode("label", {
          for: "companyEmail",
          class: "text-xs font-medium text-grays-black-5"
        }, "Company email", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "companyEmail",
          type: "email",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(v$).form.company_email.$model) = $event)),
          class: "text-xs border-none outline-none w-full rounded-md p-3 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-gray-300",
          placeholder: "Enter company's email"
        }, null, 512), [
          [_vModelText, _unref(v$).form.company_email.$model]
        ]),
        (_unref(v$).form.company_email.$dirty && _unref(v$).form.company_email.$error)
          ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Please provide a valid email "))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _cache[16] || (_cache[16] = _createElementVNode("label", {
          for: "companyTel",
          class: "text-xs font-medium text-grays-black-5"
        }, "Company phone number", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "absolute h-10 top-6 left-0 font-light outline-none placeholder-label-type-1 focus:outline-none rounded-l-lg border border-solid border-gray-type-9",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((form.value.country) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countries.value, (country) => {
            return (_openBlock(), _createElementBlock("option", {
              class: "text-sm",
              key: country.code,
              value: country.code
            }, _toDisplayString(countryCodeToEmoji(country.code)) + " " + _toDisplayString(country.phone_code), 9, _hoisted_20))
          }), 128))
        ], 512), [
          [_vModelSelect, form.value.country]
        ]),
        _withDirectives(_createElementVNode("input", {
          id: "companyTel",
          type: "tel",
          class: _normalizeClass([
            _unref(v$).form.company_phone.$dirty &&
            (_unref(v$).form.company_phone.$error || !isPhoneValid.value)
              ? 'ring-red-500'
              : 'ring-sh-grey-300'
          , "text-xs border-none outline-none w-full rounded-md p-3 pl-24 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-gray-300"]),
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(v$).form.company_phone.$model) = $event)),
          placeholder: "Enter your company's phone number"
        }, null, 2), [
          [
            _vModelText,
            _unref(v$).form.company_phone.$model,
            void 0,
            { trim: true }
          ]
        ]),
        (
            _unref(v$).form.company_phone.$dirty &&
            (_unref(v$).form.company_phone.$error || !isPhoneValid.value)
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_21, " Please provide a valid phone number "))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("button", {
        type: "button",
        class: "rounded-md w-40 flex justify-center items-center p-3 px-5 text-sm text-grays-black-5 ring-1 ring-gray-400 font-medium",
        onClick: logout
      }, " Continue Later "),
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[8] || (_cache[8] = ($event: any) => (saveForm())),
        class: _normalizeClass(["rounded-md w-32 flex justify-center items-center p-3 px-5 text-sm cursor-not-allowed", 
          _unref(v$).form.$invalid || processing.value
            ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7'
            : 'bg-sh-green-500 font-medium'
        ]),
        disabled: _unref(v$).form.$invalid || processing.value
      }, [
        _createTextVNode(_toDisplayString(processing.value ? 'Saving' : 'Next') + " ", 1),
        _cache[17] || (_cache[17] = _createElementVNode("img", {
          class: "ml-2",
          src: _imports_0
        }, null, -1))
      ], 10, _hoisted_23)
    ])
  ]))
}
}

})