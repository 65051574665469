import { ref } from 'vue'
import { useModal } from './modal'
import Confirm from '@/components/Modals/core/Confirmation.vue';
import AcceptMember from '@/components/Modals/acceptMember.vue';

type CoreTypes = 'Confirm' | 'AcceptMember';

const CoreModals = { Confirm, AcceptMember } as Record<CoreTypes, any>

export const modal = useModal(ref([] as any))

const coreModal = modal.register('Core', CoreModals)

export const useCoreModal = () => coreModal
