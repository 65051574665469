import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/square-logo.svg'
import _imports_1 from '@/assets/logo.png'
import _imports_2 from '@/assets/images/toggle.svg'
import _imports_3 from '@/assets/images/userIcon.svg'


const _hoisted_1 = { class: "h-screen flex justify-between items-center flex-col py-6 sticky top-0 bg-white" }
const _hoisted_2 = {
  key: 0,
  class: "w-6 h-6 mb-6",
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  class: "h-6"
}
const _hoisted_4 = {
  key: 0,
  class: "text-gray-400 text-xs border-b-[0.5px] px-2 py-4 w-full"
}
const _hoisted_5 = { class: "side-bar-item" }
const _hoisted_6 = { class: "flex flex-row items-center text-gray-500" }
const _hoisted_7 = { class: "font-sm sidemenu-icon-label" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  class: "text-sm font-medium whitespace-nowrap w-[100px] text-left overflow-hidden text-ellipsis"
}

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import { resetPosthogIdentity } from '@/composables/analytics';
import { useUser } from '@/composables/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'Sidebar',
  emits: ['sideBarNavigationClicked'],
  setup(__props, { emit: __emit }) {

// @click.prevent="selectThisSection(groupIndex, index)"
const { user: userObj, userRoles } = useUser()
const store = useStore()
const emit = __emit
const menuGroup = [
  {
    sectionTitle: 'GENERAL',
    menus: [
      {
        title: 'Dashboard',
        routeName: 'dashboard',
        icon: 'home',
        selected: true,
        memberRole: ['partner_earnings_manager', 'partner_trip_manager']
      },
      {
        title: 'Vehicles',
        routeName: 'vehicles.list',
        icon: 'directions_car',
        selected: false,
        memberRole: ['partner_vehicle_manager']
      },
      {
        title: 'Drivers',
        routeName: 'drivers.list',
        icon: 'group',
        selected: false,
        memberRole: ['partner_driver_manager']
      },
      {
        title: 'Trips',
        routeName: 'trips.list',
        icon: 'route',
        selected: false,
        memberRole: ['partner_trip_manager']
      },
      {
        title: 'Routes',
        routeName: 'routes.list',
        icon: 'location_on',
        selected: false,
        memberRole: ['partner_trip_manager']
      },
      {
        title: 'Earnings',
        routeName: 'earnings.information',
        icon: 'payments',
        selected: false,
        memberRole: ['partner_earnings_manager']
      }
    ]
  },
  {
    sectionTitle: 'SYSTEM',
    menus: [
      {
        title: 'Settings',
        routeName: 'settings.edit.partner',
        icon: 'settings',
        selected: false,
        memberRole: []
      }
    ]
  }
]
const isSidebarFolded = ref(false);
const windowWidth = ref(0);
const isMobileScreen = ref(false);

const user:any = computed(() => store.getters['auth/user'])

const selectThisSection = (groupIndex: number, itemIndex: number) => {
  for (let index = 0; index < menuGroup.length; index++) {
    const groupMenus = menuGroup[index].menus;
    for (let indexJ = 0; indexJ < groupMenus.length; indexJ++) {
      const section = groupMenus[indexJ];
      if (index === groupIndex && indexJ === itemIndex) {
        emit('sideBarNavigationClicked', section.title);
        section.selected = true;
      } else section.selected = false;
    }
  }
}
const toggleSidebar = () => {
  isSidebarFolded.value = !isSidebarFolded.value;
}
const checkScreen = () => {
  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 768) {
    isSidebarFolded.value = true;
    isMobileScreen.value = true;
  } else {
    isSidebarFolded.value = false;
    isMobileScreen.value = false;
  }
}
const logout = () => {
  resetPosthogIdentity()
  // window.$zoho.salesiq.reset();
  localStorage.clear();
  router.push('/login');
  router.go(0);
}

const checkIfMemberHaveAccess = (memberRoles: string[]) => {
  for (const role of memberRoles) {
    if (userRoles.value.includes(role)) {
      return true
    }
  }
  return false
}

// window.addEventListener('resize', checkScreen);
// checkScreen();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["bg-white", isSidebarFolded.value ? 'w-12 pl-0 pr-0' : 'w-64 pl-4 pr-4'])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex mb-9", 
          isSidebarFolded.value ? 'flex-col items-center' : 'flex-row items-center justify-between'
        ])
      }, [
        (isSidebarFolded.value)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_openBlock(), _createElementBlock("img", _hoisted_3)),
        (!isMobileScreen.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              onClick: toggleSidebar,
              class: "w-6 h-6 transform hover:scale-105 transition ease-out duration-500 hidden md:flex",
              src: _imports_2
            }))
          : _createCommentVNode("", true)
      ], 2),
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuGroup, (group, groupIndex) => {
        return _createElementVNode("div", {
          key: groupIndex,
          class: "mb-5"
        }, [
          (!isSidebarFolded.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(group.sectionTitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.menus, (menu, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "item"
              }, [
                (_unref(userObj)?.role === 'partner_owner' || (menu?.memberRole?.length && checkIfMemberHaveAccess(menu?.memberRole) || !menu?.memberRole?.length))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: _normalizeClass(["h-12 rounded-lg py-4 flex flex-row transform hover:scale-95 transition ease-out duration-200", {
                'justify-center': isSidebarFolded.value,
                'justify-between pl-5': !isSidebarFolded.value
              }]),
                      to: { name: menu.routeName }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", {
                            class: _normalizeClass(["material-icons sidemenu-icon", {
                    'mr-3': !isSidebarFolded.value
                  }])
                          }, _toDisplayString(menu.icon), 3),
                          _createElementVNode("span", _hoisted_7, _toDisplayString(isSidebarFolded.value ? '' : menu.title), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["h-5 w-1 sidebar-active-status", !isSidebarFolded.value ? '' : 'hidden'])
                          }, null, 2)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["class", "to"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ])
      }), 64))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-between items-center py-2 space-x-3 rounded-xl bg-green-200 w-10/12", isSidebarFolded.value ? 'p-1 flex-col' : 'px-3 flex-row'])
    }, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'settings.edit.partner' }))),
        class: "flex flex-row items-center cursor-pointer w-full"
      }, [
        (user.value.avatar)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: user.value.avatar,
              class: _normalizeClass(["w-10 h-10 mr-2 rounded-2xl", isSidebarFolded.value && 'text-center mr-0'])
            }, null, 10, _hoisted_8))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _imports_3,
              class: _normalizeClass(["w-10 h-10 mr-2", isSidebarFolded.value && 'text-center mr-0'])
            }, null, 2)),
        (!isSidebarFolded.value && user.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(user.value.fname), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        onClick: logout,
        class: _normalizeClass(["material-icons w-2/12 flex justify-end items-end cursor-pointer", isSidebarFolded.value ? 'mt-5' : ''])
      }, " logout ", 2)
    ], 2)
  ]))
}
}

})