import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { useCreateMember } from './composables/backend/members';
import inviteMember from './views/app/settings/components/settings/inviteMember.vue';
import ModalBase from './components/core/modal/ModalBase.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { showModal } = useCreateMember()


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_unref(showModal))
      ? (_openBlock(), _createBlock(inviteMember, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(ModalBase)
  ], 64))
}
}

})