import { ref, computed } from "vue"
import {axiosInstance as axios} from '@/plugins/axios';
import { useStore } from 'vuex';
import {useToast} from 'vue-toast-notification';

const toast = useToast()
const loading = ref(false)
const members = ref([] as Record<string, any>[])
const totalRecords = ref(null as null|number)

export const useFetchMembers = () => {
  // const store = useStore()
  // const partnerContext:any = computed(() => store.getters['auth/activeContext'])

  const fetchMembers = async (partnerId:number, page = 1) => {
    loading.value = true
    try {
      // const res = await axios.get(`/v1/partners/${partnerContext.value?.partner?.account_sid}/members`)
      const res = await axios.get(`/v1/account_members?owner_id=${partnerId}&owner_type=partner&page=${page}&perPage=${10}`)
      if (res.status === 200) {
        members.value = res.data?.data?.length ? res.data?.data : []
        totalRecords.value = res?.data?.metadata?.total
      }
    } catch (error:any) {
      toast.error(error?.response?.data.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading.value = false
    }
  }

  return { loading, fetchMembers, members, totalRecords }
}
