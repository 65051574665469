import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["required", "placeholder", "value"]
const _hoisted_2 = {
  key: 0,
  class: "space-y-2 rounded-lg shadow-md absolute top-[50px] z-40"
}
const _hoisted_3 = { class: "bg-gray-50 rounded-lg" }
const _hoisted_4 = ["onClick"]

import { ref, watch } from 'vue';
import { usePlacesAutocomplete } from 'v-use-places-autocomplete';
import { onClickOutside } from '@vueuse/core'

export interface Props {
	placeHolder?: string
	isRequired?: boolean
  inputValue: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ordinaryAutoComplete',
  props: {
    placeHolder: { default: 'Enter your address' },
    isRequired: { type: Boolean, default: true },
    inputValue: {}
  },
  emits: ['autoCompleteAddress'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const props = __props

const target = ref(null)
const query = ref('');
const showDropdown = ref(false);
const handleInput = (val:string) => {
  query.value = val
  emit('autoCompleteAddress', val);
}
const { suggestions } = usePlacesAutocomplete(query, {
  debounce: 500,
  minLengthAutocomplete: 3
});

function handleSelected (selectedAddressValue:any) {
  emit('autoCompleteAddress', selectedAddressValue);
  showDropdown.value = false;
  selectedAddressValue = null;
}

onClickOutside(target, () => {
  showDropdown.value = false
})

watch(query, () => {
  showDropdown.value = true;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative w-full",
    ref_key: "target",
    ref: target
  }, [
    _createElementVNode("input", {
      type: "text",
      required: _ctx.isRequired,
      class: "text-xs border-none outline-none w-full rounded-md p-3 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-gray-300",
      placeholder: _ctx.placeHolder,
      value: _ctx.inputValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (handleInput(($event.target as HTMLInputElement).value)))
    }, null, 40, _hoisted_1),
    (showDropdown.value)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(suggestions), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                onClick: ($event: any) => (handleSelected(item.description)),
                class: "py-3 text-xs ring-1 ring-white cursor-pointer px-2",
                key: item.place_id
              }, _toDisplayString(item.description), 9, _hoisted_4))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})