import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/completed.svg'
import _imports_1 from '@/assets/icons/pending.svg'
import _imports_2 from '@/assets/icons/purple-arrow.svg'


const _hoisted_1 = { class: "flex flex-row justify-between w-full" }
const _hoisted_2 = { class: "flex flex-row space-x-2 mb-4" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1
}
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row justify-center items-center"
}
const _hoisted_6 = {
  key: 0,
  class: "text-white text-sm font-medium p-1 bg-black rounded"
}




export default /*@__PURE__*/_defineComponent({
  __name: 'CheckList',
  props: {
    item: {},
    status: {},
    actionRoute: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.status == 'completed')
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_openBlock(), _createElementBlock("img", _hoisted_4)),
      _createElementVNode("span", null, _toDisplayString(_ctx.item), 1)
    ]),
    (_ctx.status === `under-review`)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.status == 'under-review')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "under review"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === `pending`)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          class: _normalizeClass([[_ctx.item === 'Upload company documents' ? 'cursor-not-allowed' : ''], "flex flex-row justify-center items-center"]),
          to: _ctx.item === 'Upload company documents' ? '' : _ctx.actionRoute
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "text-indigo-500" }, "upload", -1),
            _createElementVNode("img", {
              class: "ml-2",
              src: _imports_2
            }, null, -1)
          ])),
          _: 1
        }, 8, ["class", "to"]))
      : _createCommentVNode("", true)
  ]))
}
}

})