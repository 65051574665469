import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "chart",
  class: "bg-white p-4 lg:space-x-6 lg:space-y-4 space-y-2 lg:p-6 rounded-md shadow-md lg:w-5/12 mt-7"
}

import { ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

export default /*@__PURE__*/_defineComponent({
  __name: 'PieChart',
  props: {
    upcomingTripsCount: {},
    completedTripsCount: {}
  },
  setup(__props: any) {

const apexchart = VueApexCharts

const props = __props
const chartOptions = ref({
  labels: [`Completed trips: ${props.completedTripsCount || 0} trips`, `Incompleted trips: ${props.upcomingTripsCount || 0} trips`],
  colors: ['#20E682', '#E8E8E8'],
  chart: {
    type: 'donut'
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          toolbar: {
            show: false
          },
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        legend: {
          position: 'bottom'
        }
      }
    }
  ],
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    pie: {
      donut: {
        size: '90%',
        labels: {
          show: false,
          value: {
            offsetY: -8, // -8 worked for me
            color: '#ff00ff'
          }
        }
      },
      expandOnClick: false
    }
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex justify-between items-center" }, [
      _createElementVNode("h1", { class: "text-gray-400 font-medium text-sm" }, "Drivers Compliance")
    ], -1)),
    _createVNode(_unref(apexchart), {
      class: "",
      width: "400",
      height: "250",
      type: "donut",
      options: chartOptions.value,
      series: [_ctx.completedTripsCount, _ctx.upcomingTripsCount]
    }, null, 8, ["options", "series"])
  ]))
}
}

})