import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/images/backgroundGraphics.svg'


const _hoisted_1 = { class: "flex items-center min-h-screen justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16" }
const _hoisted_2 = { class: "flex flex-col md:w-5/12 lg:w-7/12 w-11/12 mx-auto px-4 py-8 z-10 bg-white rounded-lg" }
const _hoisted_3 = { class: "space-y-1 flex justify-between items-center flex-col" }
const _hoisted_4 = { class: "text-sh-grey-900 font-bold text-lg lg:text-2xl text-center" }
const _hoisted_5 = { class: "mt-4 text-grays-black-5 font-light text-sm md:text-base text-center" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex items-center space-x-5 mx-auto my-8" }

import { ref, Ref } from 'vue';
import EmailLogin from './EmailLogin.vue';
import PhoneLogin from './PhoneLogin.vue';
import {axiosInstance as axios} from '@/plugins/axios';
import { sendAnalytics } from '@/composables/analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  emits: ['goToSignUp'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const activeForm = ref('EmailLogin');
const otpActive = ref(false);
const otpText = ref('');
const countries = ref([]) as Ref<any[]>

const setOtpActive = (value: string) => {
  otpActive.value = !!value;
  otpText.value = value;
}
const fetchCountries = async () => {
  const response = await axios.get(`/v1/countries`);
  countries.value = response.data?.data;
}

fetchCountries()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              src: _imports_0,
              class: "h-10"
            }, null, -1)),
            _createElementVNode("h1", _hoisted_4, _toDisplayString(otpActive.value ? 'Confirm OTP' : 'Welcome Partner'), 1),
            _createElementVNode("p", _hoisted_5, [
              (otpActive.value)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    innerHTML: otpText.value
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, "Log into your account"))
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: _normalizeClass([{
              'text-sh-green-700 bg-sh-green-100 p-3 rounded-md':
                activeForm.value === 'EmailLogin'
            }, "text-grays-black-5 text-xs font-medium"]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (activeForm.value = 'EmailLogin'))
            }, " Email address ", 2),
            _createElementVNode("button", {
              class: _normalizeClass([{
              'text-sh-green-700 bg-sh-green-100 p-3 rounded-md':
                activeForm.value === 'PhoneLogin'
            }, "text-grays-black-5 text-xs font-medium"]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (activeForm.value = 'PhoneLogin'))
            }, " Phone number ", 2)
          ]),
          (_openBlock(), _createBlock(_resolveDynamicComponent(activeForm.value === 'EmailLogin' ? EmailLogin : PhoneLogin), {
            onGoToSignUp: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('goToSignUp'))),
            onOtpStateChange: _cache[3] || (_cache[3] = ($event: any) => (setOtpActive($event))),
            countries: countries.value || []
          }, null, 40, ["countries"]))
        ])
      ]),
      _: 1
    }),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "hidden lg:flex absolute bottom-0 z-0" }, [
      _createElementVNode("img", {
        src: _imports_1,
        class: "w-auto"
      })
    ], -1))
  ]))
}
}

})