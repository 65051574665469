import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"




export default /*@__PURE__*/_defineComponent({
  __name: 'FormContainer',
  props: {
    hasRing: { type: Boolean },
    extraClass: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-11/12 lg:w-6/12 rounded-md py-8 mx-auto flex justify-center items-center flex-col space-y-8 mt-6", _ctx.hasRing ? 'ring-1 ring-gray-300' : ''])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})