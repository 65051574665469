import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "flex flex-col text-center mx-auto space-y-3 mt-10" }
const _hoisted_2 = { class: "text-sh-grey-900 font-bold text-2xl" }
const _hoisted_3 = { class: "text-grays-black-5 font-light" }




export default /*@__PURE__*/_defineComponent({
  __name: 'CenteredPageHeader',
  props: {
    title: {},
    description: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "mx-auto h-10",
      src: _imports_0,
      alt: "shuttlers banner"
    }, null, -1)),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
  ]))
}
}

})