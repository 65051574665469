import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["placeholder", "disabled"]
const _hoisted_3 = {
  ref: "target",
  class: "flex flex-col absolute top-1/2 -translate-y-1/2 z-20"
}
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = { class: "truncate" }
const _hoisted_6 = { class: "relative w-full flex flex-col gap-6 text-sm" }
const _hoisted_7 = { class: "flex flex-col gap-6 text-sm mt-14 overflow-y-auto md:max-h-96 max-h-40 hide-scrollbar" }
const _hoisted_8 = { class: "text-xl" }
const _hoisted_9 = { class: "cursor-pointer flex items-center text-base" }
const _hoisted_10 = { class: "ml-auto text-neut5" }

import { ref, onMounted, computed } from 'vue'
import { DropdownMenuRoot, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from 'radix-vue'
import countries from '@/helper/countries'
import { ChevronDown } from 'lucide-vue-next'

interface Country {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InputPhone',
  props: {
  placeholder: {
    type: String,
    default: 'Enter Phone number'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  code: {
    type: String,
    default: ''
  },
  phone: {
    type: String,
    default: ''
  }
},
  emits: ['update:code', 'update:phone'],
  setup(__props, { emit: __emit }) {

const selectedCountry = ref<Country>({ name: 'Nigeria', flag: '🇳🇬', code: 'NG', dial_code: '+234' })
const searchQuery = ref('')
const phone_number = ref('')

const props = __props

const emit = __emit

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement
  //   const phone = `${dial_code.value}${Number(target.value)}`
  const phone = target.value
  emit('update:phone', phone)
}

const clearInput = () => {
  phone_number.value = ''
  emit('update:phone', '')
}

const onDropdownClick = (country: Country) => {
  selectedCountry.value = country
  emit('update:code', selectedCountry.value?.code)
}

const filteredCountries = computed(() => {
  return countries.filter((country) => country.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
})

onMounted(() => {
  const x = props.code || 'NG'
  selectedCountry.value = countries.filter((el) => el?.code === x)?.[0]
  emit('update:code', selectedCountry.value?.code)
  phone_number.value = props.phone
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
      id: "phone",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((phone_number).value = $event)),
      name: "phone",
      autocomplete: "off",
      placeholder: __props.placeholder,
      type: "number",
      class: "input-field w-full !pl-[115px] !outline-none",
      disabled: __props.disabled,
      required: "",
      onInput: handleInput
    }), null, 16, _hoisted_2), [
      [_vModelText, phone_number.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(DropdownMenuRoot), null, {
        default: _withCtx(({ open }) => [
          _createVNode(_unref(DropdownMenuTrigger), {
            as: "button",
            disabled: __props.disabled,
            class: "cursor-pointer flex items-center gap-2 p-1.5 px-2 py-0 bg-transparent border-r border-neut3 focus:outline-none"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(selectedCountry.value.flag), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(selectedCountry.value.dial_code), 1),
              _createVNode(_unref(ChevronDown), {
                size: 14,
                class: _normalizeClass(['ml-[-4px] w-4 duration-300', open ? 'rotate-180' : ''])
              }, null, 8, ["class"])
            ]),
            _: 2
          }, 1032, ["disabled"]),
          _createVNode(_unref(DropdownMenuContent), { class: "bg-white border z-20 px-8 py-6 absolute top-[2rem] -left-[60px] w-[325px] rounded-lg overflow-hidden max-h-96 min-h-28" }, {
            default: _withCtx(() => [
              _createElementVNode("section", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchQuery).value = $event)),
                  type: "text",
                  placeholder: "Search country",
                  class: "p-2 border-b border-gray-300 !outline-none absolute top-0 w-full"
                }, null, 512), [
                  [_vModelText, searchQuery.value]
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCountries.value, (country) => {
                    return (_openBlock(), _createBlock(_unref(DropdownMenuItem), {
                      key: country.code,
                      class: "flex items-center gap-3 !outline-none",
                      onSelect: ($event: any) => (onDropdownClick(country))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(country.flag), 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(country.name), 1),
                        _createElementVNode("span", _hoisted_10, _toDisplayString(country.dial_code), 1)
                      ]),
                      _: 2
                    }, 1032, ["onSelect"]))
                  }), 128))
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 512)
  ]))
}
}

})