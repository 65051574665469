import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/shuttle.svg'
import _imports_1 from '@/assets/images/arrow.svg'


const _hoisted_1 = { class: "flex flex-wrap items-center justify-center space-y-5 md:space-y-0 w-10/12 mx-auto md:w-10/12 lg:w-6/12" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "text-sh-grey-900 font-bold" }
const _hoisted_4 = { class: "font-light text-xs text-sh-grey-900" }
const _hoisted_5 = ["disabled"]

import { ref, computed } from 'vue';
import CenteredPageHeader from '../../components/CenteredPageHeader.vue';
import OnboardingLayout from '../layouts/OnboardingLayout.vue';
import { extractErrorMessage } from '@/utils/helper';
import { useStore } from 'vuex';
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import { sendAnalytics } from '@/composables/analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerSignUp',
  setup(__props) {

const route = useRoute()
const toast = useToast()
const store = useStore()
const activeIndex = ref(null);
const loading = ref(false);
const headerTitle = ref('Create a partner account');
const headerDescription = ref('Select a category to sign up as');
const signupOptions = ref([
  {
    title: 'Registered Business',
    description: 'You own a company that rents vehicles for an agreed time and fee.'
  },
  {
    title: 'An Individual',
    description: 'You are not a company but you rent out one or more vehicles for an agreed time and fee.'
  }
]);

const user:any = computed(() => store.getters['auth/user'])
const userSessionData:any = computed(() => store.getters['auth/userSessionData'])

const selected = (index: any) => {
  activeIndex.value = index;
}
const handleRedirection = async () => {
  const type = activeIndex.value === 0 ? 'Business' : 'Individual'
  sendAnalytics('Partner Selected Partner Type', {
    partner_type: type
  })
  if (activeIndex.value === 0) {
    await router.push({ name: 'GetStarted', query: { type: 'company' }});
  }

  if (activeIndex.value === 1) {
    try {
      loading.value = true;
      const response = await axios.post('/v1/partners', { mode: 'individual'});
      if (response.data) {
        await store.dispatch('auth/refreshActiveContext', user.value.id);
        await router.push({ name: 'GetStarted', query: { type: 'individual' }});
      }
    } catch (err) {
      const errorMessage = extractErrorMessage(err, null, 'Oops! An error occurred, please try again.');
      toast.error(errorMessage, { position: 'top-right' });
    } finally {
      loading.value = false;
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OnboardingLayout, null, {
    default: _withCtx(() => [
      _createVNode(CenteredPageHeader, {
        title: headerTitle.value,
        description: headerDescription.value
      }, null, 8, ["title", "description"]),
      _createElementVNode("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(signupOptions.value, ({ title, description }, index) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (selected(index)),
            key: index,
            class: _normalizeClass([[
          activeIndex.value === index
            ? 'bg-sh-green-100 ring-1 ring-sh-green-500'
            : 'bg-sh-grey-50 ring-1 ring-gray-300'
        ], "p-6 py-9 rounded-md space-y-3 cursor-pointer max-w-xs mr-0 md:mr-8 mb-3 md:mb-0"])
          }, [
            _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
            _createElementVNode("h1", _hoisted_3, _toDisplayString(title), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(description), 1)
          ], 10, _hoisted_2))
        }), 128))
      ]),
      (activeIndex.value !== null)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "mt-11 bg-sh-green-500 lg:w-4/12 w-7/12 text-sh-grey-900 rounded-md text-sm font-medium flex justify-center p-3 items-center",
            disabled: loading.value,
            onClick: handleRedirection
          }, [
            _createTextVNode(_toDisplayString(loading.value ? 'Processing' : 'Proceed') + " ", 1),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "ml-1",
              src: _imports_1
            }, null, -1))
          ], 8, _hoisted_5))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})