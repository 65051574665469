import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/arrow.svg'


const _hoisted_1 = { class: "flex flex-wrap items-center justify-center mx-auto lg:w-7/12 xl:w-6/12" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "rounded-full text-center flex justify-center items-center w-10 h-10 font-bold bg-sh-green-200 text-sh-green-500" }
const _hoisted_4 = { class: "text-sh-grey-900 font-bold text-center md:text-sm" }
const _hoisted_5 = { class: "text-xs text-grays-black-5 text-center" }

import { ref, Ref, } from 'vue';
import OnboardingLayout from '@/views/layouts/OnboardingLayout.vue';
import CenteredPageHeader from '@/components/CenteredPageHeader.vue';
import { PartnerOrganization } from '@/models/organisation.model';
import { useStore } from 'vuex';
import router from '@/router';
import {useToast} from 'vue-toast-notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganizationSelection',
  setup(__props) {

const toast = useToast()
const store = useStore()
const title = ref("Who's using Shuttlers?");
const description = ref("With Shuttlers Vehicle partner portal, you can shuffle between your organisations.");
const activeIndex = ref(-1);
const organizations = ref([]) as Ref<any[]>

const getPartnerMembers = () => {
  const members = store.getters['auth/userSessionData'];
  organizations.value = members.associatedOrganizations;
}
const selected = (index: number, partner: PartnerOrganization) => {
  if (partner.role !== 'owner' && !partner.supportedCities) {
    toast.info('This profile setup is yet to be completed', { position: 'top-right' });
    return;
  }
  store.dispatch('auth/setActiveContext', partner);
  activeIndex.value = index;
}
const gotoDashBoard = () => {
  router.push('/dashboard');
}
const getOrganizationRole = (role: PartnerOrganization['role']) => {
  switch (role) {
    case 'owner':
      return 'Owner';
    case 'admin':
      return 'Admin';
    case 'staff':
      return 'Staff';
    case 'super-admin':
      return 'Super Admin';
  }
}

getPartnerMembers()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OnboardingLayout, null, {
    default: _withCtx(() => [
      _createVNode(CenteredPageHeader, {
        title: title.value,
        description: description.value
      }, null, 8, ["title", "description"]),
      _createElementVNode("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organizations.value, (organization, index) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (selected(index, organization)),
            key: index,
            class: _normalizeClass([[
          activeIndex.value == index
            ? 'bg-sh-green-100 ring-1 ring-sh-green-500'
            : 'bg-sh-grey-50 ring-1 ring-gray-200'
        ], "p-6 py-9 rounded-md space-y-3 cursor-pointer w-52 mt-8 mr-6 flex justify-center items-center flex-col"])
          }, [
            _createElementVNode("small", _hoisted_3, _toDisplayString(organization?.partner?.company_name?.slice(0, 1)), 1),
            _createElementVNode("h1", _hoisted_4, _toDisplayString(organization.partner.company_name), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(getOrganizationRole(organization.role)), 1)
          ], 10, _hoisted_2))
        }), 128))
      ]),
      (activeIndex.value !== null)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "mt-11 bg-sh-green-500 text-sh-grey-900 rounded-md text-sm font-medium flex justify-center p-3 items-center w-4/12",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (gotoDashBoard()))
          }, _cache[1] || (_cache[1] = [
            _createTextVNode(" Proceed "),
            _createElementVNode("img", {
              class: "ml-1",
              src: _imports_0
            }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})