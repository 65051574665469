import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import 'vue3-carousel/dist/carousel.css';
import { computed } from 'vue';
import Register from '@/views/auth/Register.vue';

const step = 0

export default /*@__PURE__*/_defineComponent({
  __name: 'Register',
  setup(__props) {

const highlights = [
  {
    label: 'Manage your assets',
    description:
      'Keep track of all your Drivers and Vehicles in one place.',
    image: require('@/assets/images/banners/manage_banner.svg')
  },
  {
    label: 'Earn from your Vehicles',
    description:
      'Move shuttlers using your Vehicles and earn while doing so',
    image: require('@/assets/images/banners/earn_banner.svg')
  }
]

const registrationLink = computed(() => {
  return `${process.env.VUE_APP_WEB_APP_BASE_URL}/register?ref=partner`;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Register, {
    onGoToLogin: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'login' })))
  }))
}
}

})