import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, isRef as _isRef, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-6 lg:py-7 lg:px-16 bg-white grid grid-cols-1 lg:grid-cols-2 gap-4 space-y-5"
}
const _hoisted_2 = { class: "left-side lg:mr-16 lg:border-r border-gray-200 lg:pr-6" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "right-side flex flex-row justify-center items-center" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "text-2xl font-medium" }
const _hoisted_7 = { class: "text-sm md:text-base" }
const _hoisted_8 = { class: "text-sh-purple-700 underline" }
const _hoisted_9 = { class: "flex flex-col w-full py-5" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6" }
const _hoisted_12 = { class: "bg-white rounded-md shadow-sm space-y-4 p-10 pb-0 mt-12 ring-1 ring-gray-50" }

import { ref, Ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import PageLayout from '@/components/layout/PageLayout.vue';
import Earnings from '@/components/dashboard/Earnings.vue';
import Vehicles from '@/components/dashboard/Vehicles.vue';
import Drivers from '@/components/dashboard/Drivers.vue';
import Ratings from '@/components/dashboard/Ratings.vue';
import PieChart from '@/components/dashboard/PieChart.vue';
import Chart from '@/components/dashboard/Chart.vue';
import CheckList from '@/components/CheckList.vue';
import { extractErrorMessage } from '@/utils/helper';
import moment from 'moment';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import {useDashboard} from '@/composables/backend/dashboard'
import { sendAnalytics } from '@/composables/analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'Dashboard',
  setup(__props) {

const toast = useToast()
const store = useStore()
const {
  period,
  loadingStats,
  partnerStats,
  doneCount,
  isTodoComplete,
  partnerType,
  render,
  fetchDashboardSummary,
  getBarChartTripsData,
  getOverallRatings,
  checkIdentityStatuses,
  checkIfAllTodosAreDone,
  checkIfSettlementAccountHasBeenProvided,
  setPartnerType
} = useDashboard()
const periodOptions = [
  {val: '', key: 'All'},
  {val: 'current_year', key: 'This Year'},
  {val: 'current_month', key: 'This Month'},
  {val: 'current_week', key: 'This Week'},
  {val: 'today', key: 'Today'},
  {val: 'yesterday', key: 'Yesterday'},
  {val: 'last_month', key: 'Last Month'},
  {val: 'last_week', key: 'Last Week'}
]
const partnerEarnings = ref(null) as Ref<any>
const partnerVehicles = ref(null) as Ref<any>
const partnerDrivers = ref(null) as Ref<any>
const partnerTrips = ref(null) as Ref<any>
const loading = ref(false);

const partnerContext:any = computed(() => store.getters['auth/activeContext'])

watch(period, (value) => {
  sendAnalytics('Partner Filtered Dashboard Stats', { period: period.value || 'all'})
  fetchDashboardSummary(value)
})

const setTableStates = async () => {
  doneCount.value = 0
  loadingStats.value = true;
  checkIdentityStatuses();
  await fetchDashboardSummary();
  await checkIfSettlementAccountHasBeenProvided();
  await getOverallRatings();
  checkIfAllTodosAreDone();
  getBarChartTripsData();
  setPartnerType()
  loadingStats.value = false;
}

setTableStates()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageLayout, { "page-title": "Welcome" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (!_unref(isTodoComplete) && !_unref(loadingStats))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-sm text-gray-500 mb-7" }, "Complete your profile", -1)),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(CheckList, {
                    class: "text-xs md:text-base",
                    item: `Identity verification`,
                    status: 
                _unref(partnerStats).hasCompletedIdentityVerification === 'completed'
                  ? 'completed'
                  : 'under-review'
              ,
                    actionRoute: ``
                  }, null, 8, ["status"]),
                  _createVNode(CheckList, {
                    class: "text-xs md:text-base",
                    item: `Address verification`,
                    status: 
                _unref(partnerStats).hasCompletedAddressVerification === 'completed'
                  ? 'completed'
                  : 'under-review'
              ,
                    actionRoute: ``
                  }, null, 8, ["status"]),
                  (_unref(partnerType) == 'business')
                    ? (_openBlock(), _createBlock(CheckList, {
                        key: 0,
                        class: _normalizeClass(["text-xs md:text-base", [!_unref(partnerStats).hasUploadedCompanyDoc ? 'cursor-not-allowed opacity-75' : '']]),
                        item: `Upload company documents`,
                        status: 
                _unref(partnerStats).hasUploadedCompanyDoc ? 'completed' : 'pending'
              ,
                        actionRoute: `/dashboard/company-kyc`
                      }, null, 8, ["class", "status"]))
                    : _createCommentVNode("", true),
                  _createVNode(CheckList, {
                    class: "text-xs md:text-base",
                    item: `Add a Driver (Optional)`,
                    status: _unref(partnerStats).hasADriver ? 'completed' : 'pending',
                    actionRoute: `/drivers/add-driver`
                  }, null, 8, ["status"]),
                  _createVNode(CheckList, {
                    routeName: "addVehicle",
                    class: "text-xs md:text-base",
                    item: `Add a vehicle`,
                    status: _unref(partnerStats).hasAVehicle ? 'completed' : 'pending',
                    actionRoute: `/vehicles/add-vehicle`
                  }, null, 8, ["status"]),
                  _createVNode(CheckList, {
                    class: "text-xs md:text-base",
                    item: `Settlement Account details`,
                    status: 
                _unref(partnerStats).hasSettlementAccount ? 'completed' : 'pending'
              ,
                    actionRoute: `/add-account`
                  }, null, 8, ["status"])
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, " Welcome " + _toDisplayString(partnerContext.value.partner.company_name), 1),
                  _createElementVNode("p", _hoisted_7, [
                    _cache[2] || (_cache[2] = _createTextVNode(" You’re doing well, ")),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(doneCount)) + " of " + _toDisplayString(_unref(partnerType) === 'individual' ? '5' : 6), 1),
                    _cache[3] || (_cache[3] = _createTextVNode(" steps to be completed "))
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "font-medium text-gray-800 py-5" }, "Overview", -1)),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(period) ? (period).value = $event : null)),
            class: "outline-none border w-[200px] rounded-md text-neutral9 px-2"
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(periodOptions, (n) => {
              return _createElementVNode("option", {
                key: n.val,
                value: n.val
              }, _toDisplayString(n.key), 9, _hoisted_10)
            }), 64))
          ], 512), [
            [_vModelSelect, _unref(period)]
          ])
        ]),
        _createElementVNode("section", _hoisted_11, [
          _createVNode(Earnings, {
            accruedEarnings: _unref(partnerStats).partnerAccruedEarnings,
            loading: _unref(loadingStats)
          }, null, 8, ["accruedEarnings", "loading"]),
          _createVNode(Vehicles, {
            vehiclesCount: _unref(partnerStats).partnerVehicleCount,
            loading: _unref(loadingStats)
          }, null, 8, ["vehiclesCount", "loading"]),
          _createVNode(Drivers, {
            driversCount: _unref(partnerStats).partnerDriverCount,
            loading: _unref(loadingStats)
          }, null, 8, ["driversCount", "loading"]),
          _createVNode(Ratings, {
            count: _unref(partnerStats).ratingCount,
            rating: _unref(partnerStats).ratingOverTen,
            loading: _unref(loadingStats)
          }, null, 8, ["count", "rating", "loading"])
        ]),
        _createElementVNode("section", _hoisted_12, [
          _createVNode(Chart)
        ]),
        (_unref(render))
          ? (_openBlock(), _createBlock(PieChart, {
              key: 1,
              upcomingTripsCount: _unref(partnerStats).partnerUpcomingTrips || 0,
              completedTripsCount: _unref(partnerStats).partnerCompletedTrips || 0
            }, null, 8, ["upcomingTripsCount", "completedTripsCount"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})