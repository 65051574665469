import { useCoreModal } from './modals/modals'
import { ref, Ref } from 'vue'

const confirmationState = {
  description: ref(''),
  title: ref(''),
  type: ref('Alert'),
  call_function: ref(() => {}),
  loading: ref(false),
  proceedText: ref('Yes'),
  cancelText: ref('No')
}

interface AlertTypes {
	type: 'DANGER' | 'NORMAL' | 'SUCCESS'
	desc: string
    title: string
    call_function: () => void
    loading: Ref<boolean>,
    password?: Ref<string>,
    reason?: Ref<string | null>,
    proceedText?: string,
    cancelText?: string
}

export const useConfirmationModal = () => {
  const { openConfirm, closeConfirm } = useCoreModal()
  const openAlert = ({ type, desc, call_function, title, loading, proceedText = 'Yes', cancelText = 'No' }: AlertTypes) => {
    confirmationState.type.value = type
    confirmationState.description.value = desc
    confirmationState.title.value = title
    confirmationState.call_function.value = call_function
    confirmationState.loading = loading
    confirmationState.proceedText.value = proceedText
    confirmationState.cancelText.value = cancelText

    openConfirm()
  }
  const closeAlert = () => {
    confirmationState.description.value = ''
    confirmationState.title.value = ''
    confirmationState.call_function.value = () => { }
    confirmationState.proceedText.value = 'Yes'
    confirmationState.cancelText.value = 'No'
    closeConfirm()
  }

  return { ...confirmationState, openAlert, closeAlert }
}
