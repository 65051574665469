import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/arrow.svg'


const _hoisted_1 = { class: "w-full max-w-xl mx-auto" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex flex-col space-y-2" }
const _hoisted_4 = { class: "flex flex-col gap-1" }
const _hoisted_5 = ["disabled"]

import OnboardingLayout from '@/views/layouts/OnboardingLayout.vue';
import FormContainer from '@/views/layouts/FormContainer.vue';
import CenteredPageHeader from '@/components/CenteredPageHeader.vue';
// import SelectedCityBadge from '@/components/SelectedCityBadge.vue'
import { useStore } from 'vuex';
import { extractErrorMessage } from '@/utils/helper';
import moment from 'moment';
import { PartnerOrganization } from '@/models/organisation.model';
import {Ref, ref, onMounted, computed} from 'vue'
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import { resetPosthogIdentity, sendAnalytics } from '@/composables/analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'CitySelection',
  setup(__props) {

const route = useRoute()
const toast = useToast()
const store = useStore()
const selectedCities = ref([]) as Ref<any[]>
const selectedIndex = ref(-1);
const cities = ref([]) as Ref<any[]>
const loading = ref(false);
const loadingData = ref(false);
const city_ids = ref([]) as Ref<any[]>

const contextOrg:any = computed(() => store.getters['auth/activeContext'])
const user:any = computed(() => store.getters['auth/user'])

onMounted(async () => {
  await store.dispatch('auth/refreshActiveContext', user.value.id);
})

// watch: {
//   city_ids() {
//     console.log(typeof city_ids.value);
//   }
// },

const fetchCities = () => {
  loadingData.value = true;
  axios
    .get('/v1/city/all?limit=1000&fields=id,name,code,country_id')
    .then(async (res) => {
      cities.value = res.data.data;
      await store.dispatch('auth/setActiveContext', {
        ...store.getters['auth/activeContext'],
        supportedCities: res.data.data
      });
    })
    .finally(() => {
      loadingData.value = false;
    });
}
const savePartnerCities = async () => {
  if (city_ids.value.length) {
    try {
      loading.value = true;
      await Promise.all([
        ...city_ids.value.map((cityId) =>
          axios.post(
            `v1/partners/${contextOrg.value?.partner?.id}/cities`,
            { city_id: cityId }
          )
        )
      ]);
      await store.dispatch('auth/refreshActiveContext', user.value.id);
      sendAnalytics('Partner Provided Cities of Operation', {
        number_of_cities: city_ids.value?.length,
        cities: cities.value.filter(el => city_ids.value.includes(el?.id))?.map(el => el?.name)?.join(', ')
      })
      toast.success('Partner account created', { position: 'top-right' });
      router.push('/')
      sendAnalytics('Partner Completed Onboarding')
    } catch (e) {
      toast.error(extractErrorMessage(e), { position: 'top-right' });
    } finally {
      loading.value = false;
    }
  } else {
    toast.error('Please select at least one city of operation', { position: 'top-right' });
  }
}
const removeCity = (index:number) => {
  selectedCities.value.splice(index, 1);
}

const logout = () => {
  resetPosthogIdentity()
  // window.$zoho.salesiq.reset();
  localStorage.clear();
  router.push('/login');
  router.go(0);
}

fetchCities()

return (_ctx: any,_cache: any) => {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(OnboardingLayout, null, {
    default: _withCtx(() => [
      _createVNode(CenteredPageHeader, {
        title: 'What cities do you operate in?',
        description: 'We would love to know the cities you operate in, in order to proceed.'
      }),
      _createVNode(FormContainer, null, {
        default: _withCtx(() => [
          _createElementVNode("form", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[2] || (_cache[2] = _createElementVNode("label", { class: "text-grays-black-5 font-medium text-sm p-1" }, "Cities (You can select multiple Cities)", -1)),
                _createVNode(_component_v_select, {
                  class: "form-group",
                  options: cities.value,
                  reduce: (city) => city.id,
                  modelValue: city_ids.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((city_ids).value = $event)),
                  label: "name",
                  multiple: "",
                  required: ""
                }, null, 8, ["options", "reduce", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  disabled: loading.value || loadingData.value,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (savePartnerCities()), ["prevent"])),
                  type: "button",
                  class: "bg-sh-green-500 border-none outline-none py-3 rounded-md text-sm flex justify-center items-center w-full mt-11"
                }, [
                  _createTextVNode(_toDisplayString(loading.value ? 'Saving...' : 'Proceed') + " ", 1),
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    class: "ml-2",
                    src: _imports_0
                  }, null, -1))
                ], 8, _hoisted_5),
                _createElementVNode("button", {
                  type: "button",
                  class: "p-2 rounded border text-sm px-4 mx-auto w-fit",
                  onClick: _withModifiers(logout, ["prevent"])
                }, "Continue later")
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})