import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/toggle.svg'
import _imports_1 from '@/assets/images/faqs/question_mark.svg'


const _hoisted_1 = { class: "bg-gray-50 w-full min-h-screen overflow-x-hidden" }
const _hoisted_2 = { class: "w-full pt-2 bg-white" }
const _hoisted_3 = { class: "px-3 md:px-7 top-part flex flex-row justify-between items-center py-3" }
const _hoisted_4 = { class: "dashboard-name text-xl font-medium flex items-center gap-4" }
const _hoisted_5 = { class: "py-3 px-5 text-black rounded-lg font-medium flex items-center gap-4" }
const _hoisted_6 = { class: "bottom-part" }
const _hoisted_7 = { class: "px-3 md:px-7" }
const _hoisted_8 = { class: "w-full h-full scroll-px-60 space-y-6 py-7 px-6" }

import { computed } from "vue";
import { useStore } from "vuex";
import {useSidebar} from '@/composables/sidebar'

export interface Props {
  pageTitle: string
  hasTabs?: boolean
  hasBreadCrumbs?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageLayout',
  props: {
    pageTitle: {},
    hasTabs: { type: Boolean, default: false },
    hasBreadCrumbs: { type: Boolean, default: false }
  },
  setup(__props: any) {

const { openSidebar } = useSidebar()
const store = useStore()


const userSessionData = computed(() => store.getters['auth/userSessionData'])

const companyName = computed(() => {
  const x = userSessionData.value?.activeContext?.partner?.company_name
  if (x?.length > 15) {
    return `${x?.slice(0, 15)}...`
  } else {
    return x
  }
})

// const logout = async () => {
//   // TODO: block UI with overlay while logout api is called
//   try {
//     window.$zoho.salesiq.reset();
//     await axios.delete("/logout");
//   } catch (e) {
//     console.info("An error occurred while logging out");
//   } finally {
//     await store.dispatch("auth/clearSessionData");
//     await router.push("/login");
//   }
// }


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(openSidebar) && _unref(openSidebar)(...args))),
              class: "flex md:hidden",
              src: _imports_0,
              alt: ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.pageTitle), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/faq",
              class: "w-fit mx-auto text-sm py-2 px-3 hidden md:flex items-center gap-2 text-[#0DAC5C] rounded-lg font-medium"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: ""
                }, null, -1),
                _createTextVNode(" Go to FAQ ")
              ])),
              _: 1
            }),
            _createTextVNode(" " + _toDisplayString(companyName.value), 1)
          ])
        ]),
        _renderSlot(_ctx.$slots, "breadcrumbs"),
        _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "mt-5 mb-0 hidden" }, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "actionsPane")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(), _createElementBlock("div", {
        key: _ctx.$route.path
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    ])
  ]))
}
}

})