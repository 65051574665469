import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tab-item text-xs sm:text-sm md:text-base mb-2" }


export interface Props {
  to?: any
  title: string
  isActive?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TabItem',
  props: {
    to: { default: '/settings' },
    title: {},
    isActive: { type: Boolean, default: false }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "tab-container flex flex-col lg:w-max mr-2 px-2 lg:px-3 py-4",
    to: _ctx.to
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "w-full h-1 rounded-t" }, null, -1))
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})