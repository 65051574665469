import { ref, computed } from "vue"
import {axiosInstance as axios} from '@/plugins/axios';
import { useStore } from 'vuex';
import {useToast} from 'vue-toast-notification';
import { useConfirmationModal } from "@/composables/confirmation";
import { useFetchMembers } from "./fetch";

const { fetchMembers } = useFetchMembers()
const toast = useToast()
const loading = ref(false)
const member_data = ref(null as Record<string, any>|null)

export const useMemberAction = () => {
  const changeMemberActivationStatus = async () => {
    loading.value = true
    const payload = {
      disabled: (member_data.value?.disabled === 0)
    }
    try {
      const res = await axios.patch(`/v1/account_members/${member_data.value?.id}/status`, payload)
      if (res.status === 200) {
        useConfirmationModal().closeAlert()
        toast.success(res?.data?.message || 'Successful', { position: 'top-right' })
        fetchMembers(member_data.value?.owner_id)
      }
    } catch (error:any) {
      toast.error(error?.response?.data.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading.value = false
    }
  }

  const initMemberActivation = (_member_data:Record<string, any>) => {
    member_data.value = _member_data
    useConfirmationModal().openAlert({
      loading,
      title: `${member_data.value?.disabled === 1 ? 'Activate' : 'Deactivate'} member`,
      desc: 'If you proceed with this action, the member will lose access to the platform until reactivated.',
      call_function: changeMemberActivationStatus,
      type: 'NORMAL',
      proceedText: member_data.value?.disabled === 1 ? 'Activate' : 'Deactivate'
    })
  }

  const revokeAccess = async () => {
    loading.value = true
    const payload = {
      member_roles: []
    }
    try {
      const res = await axios.patch(`/v1/account_members/${member_data.value?.id}/roles`, payload)
      if (res?.status === 200 || res?.status === 201) {
        useConfirmationModal().closeAlert()
        toast.success(res?.data?.message || 'Revoked member access successfully', { position: 'top-right' });
        fetchMembers(member_data.value?.owner_id)
      }
    } catch (error:any) {
      toast.error(error?.response?.data?.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading.value = false
    }
  }

  const initRevokeMemberAccess = (_member_data:Record<string, any>) => {
    member_data.value = _member_data
    useConfirmationModal().openAlert({
      loading,
      title: 'Revoke member access',
      desc: 'If you continue with this action the member will be removed and they lose access to the platform.',
      call_function: revokeAccess,
      type: 'NORMAL',
      proceedText: 'Revoke access'
    })
  }

  return { loading, initMemberActivation, initRevokeMemberAccess }
}
