import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex space-x-2 lg:space-x-3 items-center" }
const _hoisted_2 = {
  key: 1,
  class: "h-0.5 w-6 lg:w-20 bg-gray-500"
}
const _hoisted_3 = { class: "w-full px-7" }

import { ref, Ref, computed } from 'vue';
import CompanyInformation from '../onboarding/steps/CompanyInformation.vue';
import KycInformation from '../onboarding/steps/KycInformation.vue';
import CenteredPageHeader from '../../components/CenteredPageHeader.vue';
import OnboardingLayout from '../layouts/OnboardingLayout.vue';
import FormContainer from '../layouts/FormContainer.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import router from '@/router'


export default /*@__PURE__*/_defineComponent({
  __name: 'GetStarted',
  setup(__props) {

const route = useRoute()
const store = useStore()
const currentStep = ref(0);
const routeType = ref('');
const title = ref('Create a partner account');
const description = ref('Select a category to sign up as');
const steps = ref([
  {
    component: CompanyInformation,
    label: 'companyData'
  },
  {
    component: KycInformation,
    label: 'kycIData'
  }
]);
const partnersFormData = ref({
  companyData: {
    name: '',
    rcNumber: '',
    address: '',
    typeOfIncorporatedBusiness: ''
  },
  kycData: {
    name: '',
    phone: ''
  }
});

const contextOrg:any = computed(() => store.getters['auth/activeContext'])

const next = () => {
  currentStep.value += 1;
}
const goBack = () => {
  currentStep.value = 0;
}
const redirectToCitySelection = () => {
  router.push('/city-selection');
}
const handleCompanyData = () => {
  currentStep.value = 1;
}
const initializePageState = () => {
  if (route.query.type === 'company' && !contextOrg.value) {
    currentStep.value = 0;
    routeType.value = 'company';
  } else {
    route.query.type = 'individual';
    routeType.value = 'individual';
    currentStep.value = 1;
  }
}

initializePageState()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OnboardingLayout, null, {
    default: _withCtx(() => [
      _createVNode(CenteredPageHeader, {
        title: title.value,
        description: description.value
      }, null, 8, ["title", "description"]),
      _createVNode(FormContainer, { hasRing: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (routeType.value !== 'individual')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["flex items-center space-x-1", 
            currentStep.value === 0 ? 'text-grays-black-2' : 'text-grays-black-6'
          ])
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["text-white rounded-full w-5 text-center text-xs", currentStep.value === 0 ? 'bg-grays-black-2' : 'bg-grays-black-7'])
                  }, "1", 2),
                  _cache[1] || (_cache[1] = _createElementVNode("p", { class: "lg:text-base text-sm" }, "Company information", -1))
                ], 2))
              : _createCommentVNode("", true),
            (routeType.value !== 'individual')
              ? (_openBlock(), _createElementBlock("p", _hoisted_2))
              : _createCommentVNode("", true),
            (routeType.value === 'individual' || routeType.value === 'company')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["flex items-center space-x-1", 
            currentStep.value === 1 ? 'text-grays-black-2' : 'text-grays-black-6'
          ])
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["text-white rounded-full w-5 text-center text-xs", currentStep.value === 1 ? 'bg-grays-black-2' : 'bg-grays-black-7'])
                  }, _toDisplayString(routeType.value === 'company' ? '2' : '1'), 3),
                  _cache[2] || (_cache[2] = _createElementVNode("p", { class: "lg:text-base text-sm" }, "Kyc verification", -1))
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("main", _hoisted_3, [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_openBlock(), _createElementBlock("div", { key: currentStep.value }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(steps.value[currentStep.value].component), {
                  onCompanySignUpSuccessful: handleCompanyData,
                  onGoBack: _cache[0] || (_cache[0] = ($event: any) => (goBack())),
                  onKycCompleted: redirectToCitySelection,
                  partnersFormData: (partnersFormData.value as any)[steps.value[currentStep.value].label]
                }, null, 40, ["partnersFormData"]))
              ]))
            ], 1024)),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex justify-end mt-10 lg:mt-0" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})