/* eslint-disable */

import {NavigationGuardReturn, RouteGuard} from "@/models/route-guard";
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {Store} from "vuex";
import {UserSessionModel} from "@/models/user-session.model";
import { useUser } from "@/composables/user";
import {useToast} from 'vue-toast-notification';

const toast = useToast()

const checkIfMemberHaveAccess = (memberRoles: string[], userRoles: string[]) => {
  for (const role of memberRoles) {
    if (userRoles.includes(role)) {
      return true
    }
  }
  return false
}
export class AuthGuard implements RouteGuard {
  constructor (private store: Store<any>) {
  }

  handle (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): boolean {
    const requiresAuth = to.matched.some(route => route.meta.requiresAuth);
    const noRedirect = to.matched.some(route => route.meta.noRedirect);
    const memberRole = to?.meta?.memberRole as string[];
    const isLoggedIn = this.store.getters["auth/isLoggedIn"];
    if (!isLoggedIn && requiresAuth) {
      this.store.dispatch('auth/clearSessionData');
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
      return false;
    }

    if (isLoggedIn && memberRole?.length) {
      const { userRoles, user } = useUser()
      if (user.value?.role === 'partner_owner') {
        return true
      } else if (checkIfMemberHaveAccess(memberRole, userRoles.value)) {
        return true
      } else {
        toast.error(`You do not have access to view ${to?.meta?.title as string} page`, { position: 'top-right' });
        next({
          name: "settings.edit.partner",
        });
        return false;
      }
    }

    if (isLoggedIn && !requiresAuth && noRedirect) {
      return true;
    }

    if (isLoggedIn && !requiresAuth) {
      next({
        name: "dashboard",
      });
      return false;
    }
    return true;
  }
}
