import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/upload.svg'
import _imports_1 from '@/assets/images/pdfUploaded.svg'
import _imports_2 from '@/assets/images/imageUploaded.svg'


const _hoisted_1 = {
  class: "",
  ref: "avatar-upload-form"
}
const _hoisted_2 = {
  key: 0,
  class: "space-y-2 w-full relative"
}
const _hoisted_3 = {
  key: 1,
  class: "p-4 ring-1 ring-gray-300 flex flex-row justify-left rounded-md items-center cursor-pointer w-full md:w-1/2 h-24 space-x-4"
}
const _hoisted_4 = {
  key: 0,
  src: _imports_1
}
const _hoisted_5 = {
  key: 1,
  src: _imports_2
}
const _hoisted_6 = { class: "flex flex-col w-full" }
const _hoisted_7 = { class: "doc-info-and-delete flex flex-row justify-between" }
const _hoisted_8 = { class: "text-sm text-black" }
const _hoisted_9 = { class: "text-sm text-gray-400" }
const _hoisted_10 = { key: 3 }

import Spinner from './layout/Spinner.vue';
import { ref, Ref, watch } from 'vue';
import {useToast} from 'vue-toast-notification';

interface UploadOptions {
  mimeTypes: string; // e.g *, image/jpg, pdf ...
  multiple: boolean; // component should support multiple uploads
  sizeLimit: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUpload',
  props: {
    field: {},
    uploading: { type: Boolean },
    uploadStatus: { type: Boolean }
  },
  emits: ['fileSelected', 'fileRemoved'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const toast = useToast();
const emit = __emit
const existingImage = ref({
  name: 'Vehicle License',
  size: '10'
})
const avatar = ref() as Ref<any>
const selectedFile = ref({})
const fileUploaded = ref(false)
const fileName = ref('')
const fileSize = ref('')
const uploadType = ref('image')

watch(() => props.uploadStatus, (newVal, oldVal) => {
  if (newVal === true) {
    fileName.value = existingImage.value.name;
    fileSize.value = existingImage.value.size;
  }
})

const uploadFile = (event: any) => {
  if (isFileSizeOk(event.target.files[0].size)) {
    selectedFile.value = event.target.files[0];
    fileSize.value = (event.target.files[0].size / 1000000)
      .toFixed(1)
      .toString();
    fileUploaded.value = true;
    fileName.value = event.target.files[0].name;
    if (event.target.files[0].type === 'application/pdf') {
      uploadType.value = 'pdf';
    } else {
      uploadType.value = 'image';
    }
    emit('fileSelected', selectedFile.value)
    // this.$emit('fileSelected', this.selectedFile);
  } else {
    toast.warning('File must be less than 5 MB', { position: 'top-right' });
  }
}
const isFileSizeOk = (fileSizeInBytes: number): boolean => {
  if (fileSizeInBytes > 5000000) {
    return false;
  } else {
    return true;
  }
}
const removeFile = () => {
  selectedFile.value = {};
  fileUploaded.value = false;
  fileName.value = '';
  fileSize.value = '';
  // this.$emit('fileRemoved');
  emit('fileRemoved')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", _hoisted_1, [
      _createElementVNode("input", {
        type: "file",
        accept: "image/*,.pdf",
        ref_key: "avatar",
        ref: avatar,
        onChange: uploadFile,
        class: "hidden"
      }, null, 544)
    ], 512),
    (!fileUploaded.value && !_ctx.uploading && !_ctx.uploadStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "py-8 ring-1 ring-gray-300 w-full flex flex-col rounded-md items-center cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (avatar.value.click()))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("img", { src: _imports_0 }, null, -1),
            _createElementVNode("p", { class: "text-sm text-green-400" }, "Click to upload", -1),
            _createElementVNode("p", { class: "text-xs text-gray-400" }, "or drag and drop (5mb maximum)", -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    ((fileUploaded.value && !_ctx.uploading) || _ctx.uploadStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (uploadType.value === 'pdf')
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : (_openBlock(), _createElementBlock("img", _hoisted_5)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(fileName.value), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(fileSize.value) + " MB", 1)
              ])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "doc-info-and-delete flex flex-row justify-between items-center" }, [
              _createElementVNode("div", { class: "bg-green-500 w-10/12 h-2 rounded-2xl" }),
              _createElementVNode("p", { class: "w-2/12 text-right" }, "100%")
            ], -1))
          ])
        ]))
      : _createCommentVNode("", true),
    (fileUploaded.value && !_ctx.uploading)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (avatar.value.click())),
          class: "text-sh-purple-700 font-medium text-sm mb-4"
        }, " Change Document "))
      : _createCommentVNode("", true),
    (_ctx.uploading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(Spinner)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})