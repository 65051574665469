import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal-title flex justify-between w-full items-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-center w-full"
}
const _hoisted_3 = { class: "w-full relative h-full" }
const _hoisted_4 = { class: "sidebar" }

import { PropType, watch, ref } from 'vue'
import { modal } from '@/composables/modals/modals'
import { useRoute } from 'vue-router'

type modalTypes = 'popup' | 'sidebar';

export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
  modalClass: {
    default: '',
    type: String
  },
  baseModal: {
    default: true,
    type: Boolean,
    required: false
  },

  autoClose: {
    default: true,
    type: Boolean,
    required: false
  },
  propsModal: {
    type: String,
    required: false,
    default: 'modal'
  },
  title: {
    default: 'Default Title',
    type: String,
    required: false
  },
  showTitle: {
    default: true,
    type: Boolean,
    required: false
  },
  noCloseBtn: {
    default: false,
    type: Boolean,
    required: false
  },
  type: {
    default: 'popup',
    type: String as PropType<modalTypes>,
    required: false
  },
  modalWidth: {
    default: '100%',
    type: String,
    required: false
  },
  modalStyle: {
    default: '',
    type: String,
    required: false
  }
},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

// import gsap from 'gsap'
const emit = __emit

watch(useRoute(), (from, to) => {
  closeModal()
  emit('close')
})
const props = __props

const close = (e: any) => {
  if (
    typeof e.className === 'string' &&
		e.className.includes('modal-background')
  ) {
    emit('close')
    return closeModal()
  }
}

const closeModal = () => {
  modal.close(props.propsModal)
}

return (_ctx: any,_cache: any) => {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    close: closeModal,
    class: _normalizeClass([
			__props.type == 'popup' ? 'bg-modal' : 'bg-sidebar',
			'transition-all modal-background',
		]),
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (__props.autoClose ? close(_ctx.$el) : null), ["self"]))
  }, [
    _createVNode(_Transition, {
      name: "modal",
      appear: ""
    }, {
      default: _withCtx(() => [
        (__props.type == 'popup')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([[__props.modalClass], "modal flex flex-col"]),
              style: _normalizeStyle(__props.modalStyle)
            }, [
              _createElementVNode("header", _hoisted_1, [
                (__props.showTitle)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.title), 1))
                  : _createCommentVNode("", true),
                (__props.noCloseBtn)
                  ? (_openBlock(), _createBlock(_component_icon, {
                      key: 1,
                      name: "close",
                      class: "text-black w-7 cursor-pointer",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal()))
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ], 6))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    (__props.type == 'sidebar')
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "slide",
          appear: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})