import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen w-screen flex justify-center items-center flex-col space-y-6" }

import { } from 'vue';

// inheritAttrs: false,


export default /*@__PURE__*/_defineComponent({
  __name: 'OnboardingLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, _mergeProps({
    name: "fade",
    mode: "out-in"
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createElementVNode("main", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 16))
}
}

})