import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center -mt-4 w-full gap-4" }
const _hoisted_2 = { class: "flex flex-col gap-2.5" }
const _hoisted_3 = { class: "text-center text-lg text-[#09090F] font-medium" }
const _hoisted_4 = { class: "text-center text-base text-[#737876]" }
const _hoisted_5 = { class: "grid grid-cols-2 gap-4 mt-10 w-full" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { key: 0 }

import { useConfirmationModal } from '@/composables/confirmation'
import {ref, computed} from 'vue'
import Modal from '@/components/core/modal/Modal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Confirmation',
  setup(__props) {

const { call_function, closeAlert, description, title, loading, type, proceedText, cancelText } = useConfirmationModal()

const buttonStyle = computed(() => {
  if (type.value === 'DANGER') return 'border-red bg-[#fda29bb0] text-[#B01C23]'
  if (type.value === 'NORMAL') return 'border-dark bg-black text-light'
  return 'border-dark bg-black text-light'
})


return (_ctx: any,_cache: any) => {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createBlock(Modal, {
    modal: "$atts.modal",
    title: "",
    class: "!text-sm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("svg", {
          width: "56",
          height: "56",
          viewBox: "0 0 56 56",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("rect", {
            x: "4",
            y: "4",
            width: "48",
            height: "48",
            rx: "24",
            fill: "#FFF3AD"
          }),
          _createElementVNode("path", {
            d: "M23.8826 24.679C23.8806 24.7274 23.8885 24.7758 23.9058 24.8211C23.9231 24.8664 23.9495 24.9076 23.9834 24.9423C24.0172 24.977 24.0578 25.0044 24.1027 25.0229C24.1475 25.0413 24.1957 25.0504 24.2441 25.0495H25.4816C25.6886 25.0495 25.8536 24.88 25.8806 24.6745C26.0156 23.6905 26.6906 22.9735 27.8936 22.9735C28.9226 22.9735 29.8646 23.488 29.8646 24.7255C29.8646 25.678 29.3036 26.116 28.4171 26.782C27.4076 27.5155 26.6081 28.372 26.6651 29.7625L26.6696 30.088C26.6712 30.1864 26.7114 30.2803 26.7816 30.3493C26.8517 30.4183 26.9462 30.457 27.0446 30.457H28.2611C28.3606 30.457 28.456 30.4175 28.5263 30.3472C28.5966 30.2768 28.6361 30.1815 28.6361 30.082V29.9245C28.6361 28.8475 29.0456 28.534 30.1511 27.6955C31.0646 27.001 32.0171 26.23 32.0171 24.6115C32.0171 22.345 30.1031 21.25 28.0076 21.25C26.1071 21.25 24.0251 22.135 23.8826 24.679ZM26.2181 33.3235C26.2181 34.123 26.8556 34.714 27.7331 34.714C28.6466 34.714 29.2751 34.123 29.2751 33.3235C29.2751 32.4955 28.6451 31.9135 27.7316 31.9135C26.8556 31.9135 26.2181 32.4955 26.2181 33.3235Z",
            fill: "#CBAF02"
          }),
          _createElementVNode("rect", {
            x: "4",
            y: "4",
            width: "48",
            height: "48",
            rx: "24",
            stroke: "#FFF9D6",
            "stroke-width": "8"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_unref(title)), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(description)), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "modal-btn border",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(closeAlert) && _unref(closeAlert)(...args)))
          }, _toDisplayString(_unref(cancelText) || 'Cancel'), 1),
          _createElementVNode("button", {
            class: _normalizeClass(["modal-btn !bg-[#E13D45] text-white", [buttonStyle.value]]),
            disabled: _unref(loading),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(call_function)()))
          }, [
            (!_unref(loading))
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(proceedText) || 'Yes'), 1))
              : (_openBlock(), _createBlock(_component_Spinner, { key: 1 }))
          ], 10, _hoisted_6)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})