import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/arrow.svg'
import _imports_1 from '@/assets/images/password-changed.svg'
import _imports_2 from '@/assets/images/backgroundGraphics.svg'


const _hoisted_1 = { class: "flex items-center min-h-screen justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16" }
const _hoisted_2 = {
  class: "relative px-6 mt-20 py-3 pb-6 text-dark-type-4 w-8/12 mx-auto z-10 bg-white rounded-lg",
  style: {"margin-top":"80px"}
}
const _hoisted_3 = {
  key: 0,
  class: "flex items-start w-full p-3 mx-auto my-5 bg-red-200 rounded-md"
}
const _hoisted_4 = { class: "text-sm font-md text-red-500" }
const _hoisted_5 = { class: "flex flex-col space-y-2 mt-6 relative" }
const _hoisted_6 = ["type"]
const _hoisted_7 = {
  key: 0,
  class: "text-xs text-red-400"
}
const _hoisted_8 = {
  key: 1,
  class: "text-xs text-red-400"
}
const _hoisted_9 = { class: "flex flex-col space-y-2 mt-6 relative" }
const _hoisted_10 = ["type"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_13 = {
  key: 1,
  class: "text-sm font-light text-red-500"
}
const _hoisted_14 = ["disabled"]
const _hoisted_15 = {
  key: 1,
  class: "ml-2",
  src: _imports_0
}
const _hoisted_16 = {
  key: 1,
  class: "w-full max-w-md"
}
const _hoisted_17 = {
  key: 0,
  class: "hidden lg:flex absolute bottom-0 z-0"
}

import { ref, Ref, computed } from 'vue';
import { required, sameAs, minLength } from '@vuelidate/validators';
import { extractErrorMessage } from '../../utils/helper';
import useVuelidate from '@vuelidate/core';
import Spinner from '@/components/layout/Spinner.vue';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPassword',
  props: ['token'],
  setup(__props) {

const props = __props
const route = useRoute()
const toast = useToast()

const isSuccessful = ref(false);
const errorMessage = ref('');
const form = ref({
  password: '',
  confirmPassword: ''
});
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const processing = ref(false);

const validations = {
  form: {
    password: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      required,
      minLength: minLength(6),
      // sameAsPassword: sameAs(form.password.value)
    }
  }
}
const v$ = useVuelidate(validations, {form})

const toggleShow = () => {
  showPassword.value = !showPassword.value;
}
const toggleShowConfirmPassword = () => {
  showConfirmPassword.value = !showConfirmPassword.value;
}
const resetPassword = () => {
  if (form.value.password !== form.value.confirmPassword) {
    return toast.error('Password does not match', {position: 'top-right'});
  }
  v$.value.form.$touch();
  if (processing.value || v$.value.form.$error) {
    return;
  }

  processing.value = true;
  errorMessage.value = '';
  const payload = {
    password: form.value.password,
    confirm_password: form.value.confirmPassword,
    type: route.query.type,
    token: props.token
  };

  axios
    .post('v1/password/change', payload)
    .then(async () => {
      isSuccessful.value = true;
    })
    .catch((err) => {
      isSuccessful.value = false;
      toast.error(
        extractErrorMessage(
          err,
          null,
          'Oops! An error occurred, please try again.'
        ), { position: 'top-right' }
      );
    })
    .finally(() => (processing.value = false));
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!isSuccessful.value)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            onSubmit: _withModifiers(resetPassword, ["prevent"])
          }, [
            _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-sh-grey-900 font-bold text-lg lg:text-2xl text-center" }, " Create a new password ", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mt-1 text-xs font-medium text-center text-dark-type-4" }, " Try using something you can easily remember ", -1)),
            (errorMessage.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(errorMessage.value), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("label", { class: "text-grays-black-5 font-medium text-sm" }, "Password", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).form.password.$model) = $event)),
                type: showPassword.value ? 'text' : 'password',
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(v$).form.password.$touch())),
                id: "password",
                class: _normalizeClass([
                _unref(v$).form.password.$error ? 'ring-red-500' : 'ring-sh-grey-300'
              , "py-3 px-5 border-none placeholder:text-grays-black-7 outline-none ring-1 text-sm rounded-md placeholder-opacity-50"]),
                placeholder: "Type your password"
              }, null, 42, _hoisted_6), [
                [
                  _vModelDynamic,
                  _unref(v$).form.password.$model,
                  void 0,
                  { trim: true }
                ]
              ]),
              (!_unref(v$).form.password.required && _unref(v$).form.password.$error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Please enter a password"))
                : _createCommentVNode("", true),
              (!_unref(v$).form.password.minLength)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Password must have at least " + _toDisplayString(_unref(v$).form.password.$params.minLength.min) + " characters. ", 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                onClick: toggleShow,
                class: _normalizeClass(["absolute top-7 right-3 cursor-pointer text-sm font-medium", [showPassword.value ? 'text-green-500' : 'text-purple-dark']])
              }, _toDisplayString(showPassword.value ? 'Hide' : 'Show'), 3)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[6] || (_cache[6] = _createElementVNode("label", { class: "text-grays-black-5 font-medium text-sm" }, "Confirm Password", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).form.confirmPassword.$model) = $event)),
                type: showConfirmPassword.value ? 'text' : 'password',
                onInput: _cache[3] || (_cache[3] = ($event: any) => (_unref(v$).form.confirmPassword.$touch())),
                id: "confirmPassword",
                class: _normalizeClass([
                _unref(v$).form.confirmPassword.$error
                  ? 'ring-red-500'
                  : 'ring-sh-grey-300'
              , "py-3 px-5 border-none placeholder:text-grays-black-7 outline-none ring-1 text-sm rounded-md placeholder-opacity-50"]),
                placeholder: "Confirm your password"
              }, null, 42, _hoisted_10), [
                [
                  _vModelDynamic,
                  _unref(v$).form.confirmPassword.$model,
                  void 0,
                  { trim: true }
                ]
              ]),
              (_unref(v$).form.confirmPassword.$dirty)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_unref(v$).form.confirmPassword.$error)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Passwords must be identical "))
                      : _createCommentVNode("", true),
                    (!_unref(v$).form.confirmPassword.required)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, " Password must not be empty. "))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                onClick: toggleShowConfirmPassword,
                class: _normalizeClass(["absolute top-7 right-3 cursor-pointer text-sm font-medium", [showConfirmPassword.value ? 'text-green-500' : 'text-purple-dark']])
              }, _toDisplayString(showConfirmPassword.value ? 'Hide' : 'Show'), 3)
            ]),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (resetPassword()), ["prevent"])),
              type: "button",
              class: _normalizeClass(["border-none outline-none py-3 rounded-md text-sm flex justify-center items-center w-full mt-8", 
              _unref(v$).form.$invalid || processing.value
                ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7'
                : 'bg-sh-green-500 font-medium'
            ]),
              disabled: _unref(v$).form.$invalid || processing.value
            }, [
              _createTextVNode(_toDisplayString(processing.value ? 'Processing...' : 'Set Password') + " ", 1),
              (processing.value)
                ? (_openBlock(), _createBlock(Spinner, {
                    key: 0,
                    class: "w-5 ml-2 mx-0"
                  }))
                : _createCommentVNode("", true),
              (!processing.value)
                ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                : _createCommentVNode("", true)
            ], 10, _hoisted_14)
          ], 32))
        : (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _cache[10] || (_cache[10] = _createElementVNode("img", {
              src: _imports_1,
              class: "block mx-auto text-center",
              alt: "Password changed"
            }, null, -1)),
            _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "mt-2 text-2xl font-bold text-center text-dark-type-3" }, " Password changed ", -1)),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "mt-1 text-sm font-medium text-center text-dark-type-4" }, " You have successfully changed your password ", -1)),
            _createVNode(_component_router_link, {
              to: "/login",
              class: "block w-56 mx-auto mt-6 font-bold text-center text-white rounded py-3 bg-sh-green-500"
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Login")
              ])),
              _: 1
            })
          ]))
    ]),
    (!isSuccessful.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[13] || (_cache[13] = [
          _createElementVNode("img", {
            src: _imports_2,
            class: "w-auto"
          }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})