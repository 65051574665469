import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vModelDynamic as _vModelDynamic, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/PasswordHide.svg'
import _imports_1 from '@/assets/icons/PasswordShow.svg'
import _imports_2 from '@/assets/images/faqs/question_mark.svg'


const _hoisted_1 = { class: "flex items-center min-h-screen justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:py-12 lg:px-16" }
const _hoisted_2 = {
  class: "relative px-6 mt-20 py-3 pb-6 text-dark-type-4 max-w-xl lg:max-w-2xl mx-auto z-10 bg-white rounded-lg",
  style: {"margin-top":"80px"}
}
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = { class: "text-xs font-medium text-center" }
const _hoisted_5 = { class: "mt-12" }
const _hoisted_6 = { class: "flex flex-wrap -mx-3" }
const _hoisted_7 = { class: "w-full px-3 mb-6 md:w-1/2 md:mb-0" }
const _hoisted_8 = {
  key: 0,
  class: "text-xs text-red-400"
}
const _hoisted_9 = {
  key: 1,
  class: "text-xs text-red-400"
}
const _hoisted_10 = { class: "w-full px-3 md:w-1/2" }
const _hoisted_11 = {
  key: 0,
  class: "text-xs text-red-400"
}
const _hoisted_12 = {
  key: 1,
  class: "text-xs text-red-400"
}
const _hoisted_13 = { class: "mt-6" }
const _hoisted_14 = { class: "mt-2" }
const _hoisted_15 = {
  key: 0,
  class: "text-xs text-red-400"
}
const _hoisted_16 = { class: "mt-6" }
const _hoisted_17 = { class: "mt-2 relative" }
const _hoisted_18 = {
  key: 0,
  class: "text-sm text-red-500 font-light"
}
const _hoisted_19 = { class: "mt-6" }
const _hoisted_20 = { class: "relative mt-2" }
const _hoisted_21 = ["type"]
const _hoisted_22 = {
  key: 0,
  src: _imports_0
}
const _hoisted_23 = {
  key: 1,
  src: _imports_1
}
const _hoisted_24 = {
  key: 0,
  class: "text-xs text-red-400"
}
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { class: "w-11/12 mx-auto mt-2 text-xs font-medium text-center" }
const _hoisted_27 = ["href"]
const _hoisted_28 = ["href"]

import { minLength, required } from '@vuelidate/validators';
import { extractErrorMessage, hasRecaptchaKey } from '../../utils/helper';
import useVuelidate from '@vuelidate/core';
import { ref, Ref, computed, onMounted, watch } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { AxiosResponse } from 'axios';
import { LoginResponse } from '@/models/login-response.model';
import countryCodeEmoji from 'country-code-emoji';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/mobile';
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setPosthogIdentity } from '@/composables/analytics';
import InputPhone from '@/components/core/InputPhone.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Register',
  setup(__props) {

const phoneNumber = (value: string) => {
  // return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
  //   value
  // );
  if (value.length === 11 || value.length === 10) return true
  return false
};
const validName = (value: string) => {
  return /^[a-zA-Z]+(([ -][a-zA-Z])?[a-zA-Z]*)*$/.test(value);
};

const store = useStore()
const route = useRoute()
const toast = useToast()
const validations = {
  form: {
    email: {
      required
    },
    fname: {
      required,
      validName
    },
    lname: {
      required,
      validName
    },
    phone: {
      required,
      // phoneNumber,
      // minLength: minLength(11)
    },
    password: {
      required,
      minLength: minLength(6)
    }
  }
}
const loadingData = ref(false);
const showPassword = ref(false);
const processing = ref(false);
const form = ref({
  email: '',
  fname: '',
  lname: '',
  phone: '',
  password: '',
  country_code: ''
});
const errorMessage = ref('');
const countries = ref([]) as Ref<any[]>
const captchaInitialized = ref(false);
const isPhoneValid = ref(false);
const registrationContext = ref(null) as Ref<any>
const cities = ref([]) as Ref<any[]>
const reCaptchaComposition: any = useReCaptcha();
const v$ = useVuelidate(validations, {form})

// computed
const termsOfUseLink = computed(() => {
  return `${process.env.VUE_APP_WEBSITE_URL}/terms`;
})
const privacyPolicyLink = computed(() => {
  return `${process.env.VUE_APP_WEBSITE_URL}/privacy-policy`;
})

const recaptcha = async () => {
  await reCaptchaComposition?.recaptchaLoaded();
};

const showBadge = () => {
  reCaptchaComposition?.instance?.value?.showBadge();
};

const hideBadge = () => {
  reCaptchaComposition?.instance?.value?.hideBadge();
};
const execute = (action: string) => {
  return reCaptchaComposition?.executeRecaptcha(action);
};

const recaptchaInstance = {
  ...reCaptchaComposition?.instance?.value,
  hideBadge,
  showBadge,
  execute
}

const getCaptchaToken = async () => {
  if (hasRecaptchaKey()) {
    try {
      const token = await recaptchaInstance.execute(
        'partner_registration'
      );
      return token;
    } catch (error) {
      console.error('Captcha token error:', error);
      return '';
    }
  } else {
    return '';
  }
}
const verifyCaptcha = async () => {
  const token = await getCaptchaToken();
  if (captchaInitialized.value && token) {
    return axios.post('/token/captcha/validate', { token });
  } else {
    return Promise.resolve({
      data: {
        success: !hasRecaptchaKey()
      }
    });
  }
}
const register = async () => {
  v$.value.form.$touch();
  if (processing.value || v$.value.form.$error) {
    return;
  }
  processing.value = true;
  errorMessage.value = '';
  const payload = { ...form.value };
  try {
    // const captchaResponse: any = await this.verifyCaptcha();
    // if (captchaResponse.data.success) {
    const loginResponse: AxiosResponse<LoginResponse> =
      await axios.post('v1/users', payload);
    if (loginResponse?.data) {
      await store.dispatch('auth/authSuccess', loginResponse.data);
      // window.$zoho.salesiq.reset();
      setPosthogIdentity({
        email: form.value.email,
        fname: form.value.fname,
        lname: form.value.lname
      })
      const redirect: any = route.query.redirect;
      if (redirect) {
        router.push({ path: redirect });
      } else {
        router.push({
          name: 'dashboard',
          query: { ...route.query }
        });
      }
    }
  } catch (e) {
    // errorMessage.value = "Oops! An error occurred, please try again.";
    toast.error(
      extractErrorMessage(
        e,
        null,
        'Oops! An error occurred, please try again.'
      ), { position: 'top-right' }
    );
  } finally {
    processing.value = false;
  }
}
const rejectCaptchaVerification = (message: string) => {
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({
    response: {
      data: {
        message
      }
    }
  });
}
const setDefaultCountry = () => {
  const code =
    countries.value && countries.value.length
      ? (countries.value[0] as any).code
      : null;
  if (code) {
    form.value.country_code = code;
  }
}
const fetchCountries = async () => {
  const response = await axios.get(`v1/countries`);
  countries.value = response.data?.data || [];
}
const validatePhoneNumber = () => {
  isPhoneValid.value = isValidPhoneNumber(
    form.value.phone.toString(),
    form.value.country_code as CountryCode
  );
}
const countryCodeToEmoji = (code: string) => {
  return countryCodeEmoji(code);
}

watch(() => form.value.phone, (value, oldValue) => {
  validatePhoneNumber()
})

// watch(countries, (value, oldValue) => {
//   setDefaultCountry()
// })

onMounted(async () => {
  try {
    await recaptcha();
    captchaInitialized.value = true;
  } catch (e) {
    console.error(e);
  }
})

// setDefaultCountry();
fetchCountries();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "" }, [
        _createElementVNode("div", { class: "text-sh-grey-900 font-bold text-lg lg:text-2xl text-center" }, " Create a Shuttlers Account ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createTextVNode(" Already have an account? ")),
          _createElementVNode("p", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('goToLogin'))),
            class: "text-sh-green-500 cursor-pointer"
          }, " Login ")
        ])
      ]),
      _createElementVNode("form", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[10] || (_cache[10] = _createElementVNode("label", {
              class: "text-xs font-medium",
              for: "first-name"
            }, "First name", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).form.fname.$model) = $event)),
              class: "w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-label-type-1 rounded-lg border border-solid border-gray-type-9 focus:outline-none",
              type: "text",
              id: "first-name"
            }, null, 512), [
              [
                _vModelText,
                _unref(v$).form.fname.$model,
                void 0,
                { trim: true }
              ]
            ]),
            (_unref(v$).form.fname.required.$invalid && _unref(v$).form.fname.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Please enter your first name"))
              : _createCommentVNode("", true),
            (_unref(v$).form.fname.validName && _unref(v$).form.fname.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Special characters and numbers are not allowed "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[11] || (_cache[11] = _createElementVNode("label", {
              class: "text-xs font-medium",
              for: "last-name"
            }, "Last name", -1)),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).form.lname.$model) = $event)),
              class: "w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-label-type-1 rounded-lg border border-solid border-gray-type-9 focus:outline-none",
              type: "text",
              id: "last-name"
            }, null, 512), [
              [
                _vModelText,
                _unref(v$).form.lname.$model,
                void 0,
                { trim: true }
              ]
            ]),
            (_unref(v$).form.lname.required.$invalid && _unref(v$).form.lname.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Please enter your last name"))
              : _createCommentVNode("", true),
            (_unref(v$).form.lname.validName && _unref(v$).form.lname.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Special characters and numbers are not allowed "))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[12] || (_cache[12] = _createElementVNode("label", {
            class: "text-xs font-medium",
            for: "email-address"
          }, "Email address", -1)),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).form.email.$model) = $event)),
              type: "email",
              id: "email-address",
              class: "w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-label-type-1 rounded-lg border border-solid border-gray-type-9 focus:outline-none",
              placeholder: "sample@mail.com"
            }, null, 512), [
              [_vModelText, _unref(v$).form.email.$model]
            ]),
            (_unref(v$).form.email.required.$invalid && _unref(v$).form.email.$error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, "Please enter your email address"))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[13] || (_cache[13] = _createElementVNode("label", {
            class: "text-xs font-medium",
            for: "phone"
          }, "Phone number", -1)),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(InputPhone, {
              code: form.value.country_code,
              "onUpdate:code": _cache[4] || (_cache[4] = ($event: any) => ((form.value.country_code) = $event)),
              phone: form.value.phone,
              "onUpdate:phone": _cache[5] || (_cache[5] = ($event: any) => ((form.value.phone) = $event))
            }, null, 8, ["code", "phone"]),
            (
                form.value.phone?.length && !isPhoneValid.value
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, " Please provide a valid phone number "))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _cache[14] || (_cache[14] = _createElementVNode("label", {
            class: "text-xs font-medium",
            for: "password-register"
          }, "Password", -1)),
          _createElementVNode("div", _hoisted_20, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(v$).form.password.$model) = $event)),
              type: showPassword.value ? 'text' : 'password',
              id: "password-register",
              class: "w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-label-type-1 rounded-lg border border-solid border-gray-type-9 focus:outline-none"
            }, null, 8, _hoisted_21), [
              [_vModelDynamic, _unref(v$).form.password.$model]
            ]),
            _createElementVNode("div", {
              class: "absolute inset-y-0 right-0 flex items-center pr-2 text-xs font-medium cursor-pointer text-active",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (showPassword.value = !showPassword.value))
            }, [
              (showPassword.value)
                ? (_openBlock(), _createElementBlock("img", _hoisted_22))
                : (_openBlock(), _createElementBlock("img", _hoisted_23))
            ])
          ]),
          (_unref(v$).form.password.required.$invalid && _unref(v$).form.password.$error)
            ? (_openBlock(), _createElementBlock("span", _hoisted_24, "Please enter a password"))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (register()), ["prevent"])),
          type: "button",
          class: _normalizeClass(["border-none outline-none py-3 rounded-md text-sm flex justify-center items-center w-full mt-8", 
            _unref(v$).form.$invalid || processing.value
              ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7'
              : 'bg-sh-green-500 font-medium'
          ]),
          disabled: _unref(v$).form.$invalid || processing.value
        }, _toDisplayString(processing.value ? 'Processing...' : 'Register'), 11, _hoisted_25),
        _createElementVNode("div", _hoisted_26, [
          _cache[15] || (_cache[15] = _createTextVNode(" By clicking on \"Register\", I have read and agreed to Shuttlers' ")),
          _createElementVNode("a", {
            href: termsOfUseLink.value,
            target: "_blank",
            class: "text-active"
          }, "Terms of Service", 8, _hoisted_27),
          _cache[16] || (_cache[16] = _createTextVNode(" and ")),
          _createElementVNode("a", {
            href: privacyPolicyLink.value,
            target: "_blank",
            class: "text-active"
          }, "Privacy Policy", 8, _hoisted_28)
        ]),
        _createVNode(_component_router_link, {
          to: "/faq",
          class: "mt-6 w-fit mx-auto text-sm py-2 px-3 flex items-center gap-2 text-[#0DAC5C] rounded-lg font-medium"
        }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createElementVNode("img", {
              src: _imports_2,
              alt: ""
            }, null, -1),
            _createTextVNode(" Go to FAQ ")
          ])),
          _: 1
        })
      ]),
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "w-full text-center mt-16" }, [
        _createElementVNode("p", { class: "captcha-policy" }, [
          _createTextVNode(" This site is protected by reCAPTCHA and the Google "),
          _createElementVNode("a", {
            target: "_blank",
            href: "https://policies.google.com/privacy"
          }, "Privacy Policy"),
          _createTextVNode(" and "),
          _createElementVNode("a", {
            target: "_blank",
            href: "https://policies.google.com/terms"
          }, "Terms of Service"),
          _createTextVNode(" apply. ")
        ])
      ], -1))
    ])
  ]))
}
}

})