import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "lg:grid lg:min-h-screen lg:grid-cols-12" }
const _hoisted_3 = { class: "relative flex items-end bg-grays-black-2 lg:col-span-5 lg:h-full" }
const _hoisted_4 = { class: "hidden lg:relative lg:block lg:p-12 carousel-wrapper pb-20" }
const _hoisted_5 = { class: "w-full text-white flex flex-col items-center pb-8 justify-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "font-bold text-3xl leading-10 mt-16" }
const _hoisted_8 = { class: "text-grays-black-6 leading-8 mt-4" }

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import { computed } from 'vue';
const step = 0

export default /*@__PURE__*/_defineComponent({
  __name: 'Base',
  setup(__props) {

const highlights = [
  {
    label: 'Manage your assets',
    description:
      'Keep track of all your Drivers and Vehicles in one place.',
    image: require('@/assets/images/banners/manage_banner.svg')
  },
  {
    label: 'Earn from your Vehicles',
    description:
      'Move shuttlers using your Vehicles and earn while doing so',
    image: require('@/assets/images/banners/earn_banner.svg')
  }
]

const registrationLink = computed(() => {
  return `${process.env.VUE_APP_WEB_APP_BASE_URL}/register?ref=partner`;
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(Carousel), {
            "items-to-show": 1,
            autoplay: 8000,
            "wrap-around": true,
            class: "lg:pb-12"
          }, {
            addons: _withCtx(() => [
              _createVNode(_unref(Pagination))
            ]),
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(highlights, (slide, index) => {
                return _createVNode(_unref(Slide), { key: index }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("img", {
                        class: "max-w-xs",
                        src: slide.image
                      }, null, 8, _hoisted_6),
                      _createElementVNode("h2", _hoisted_7, _toDisplayString(slide.label), 1),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(slide.description), 1)
                    ])
                  ]),
                  _: 2
                }, 1024)
              }), 64))
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})