import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "bg-white w-screen h-screen top-0 lef-0 fixed z-20 p-4 md:p-6 flex flex-col items-start gap-8" }
const _hoisted_2 = { class: "flex flex-col gap-6 w-full max-w-[480px] mx-auto overflow-auto" }
const _hoisted_3 = { class: "flex flex-col gap-1" }
const _hoisted_4 = { class: "text-[#000005] text-2xl font-bold" }
const _hoisted_5 = {
  key: 0,
  class: "text-sm text-[#737876]"
}
const _hoisted_6 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_7 = { class: "flex items-center gap-4 justify-between text-sm font-normal py-2 pb-4 border-b border-dashed border-[#EFF2F7]" }
const _hoisted_8 = { class: "text-[#09090F]" }
const _hoisted_9 = { class: "flex items-center gap-4 justify-between text-sm font-normal py-4 border-b border-dashed border-[#EFF2F7]" }
const _hoisted_10 = { class: "text-[#09090F]" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col gap-1"
}
const _hoisted_12 = { class: "flex flex-col gap-4" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "flex flex-col text-left text-sm" }
const _hoisted_15 = { class: "text-[#09090F] font-medium" }
const _hoisted_16 = { class: "text-[#595E5C] font-normal text-[13px]" }
const _hoisted_17 = ["disabled"]

import { computed, onBeforeUnmount, ref } from 'vue';
import {Check, X} from 'lucide-vue-next'
import { useCreateMember } from '@/composables/backend/members';
import skeleton from '@/components/loader/skeleton.vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'inviteMember',
  setup(__props) {

const store = useStore()
const partnerContext:any = computed(() => store.getters['auth/activeContext'])
const { showModal, fetchRoles, loading, loading_roles, roles, email, selected_roles, clearObj, handleSelectRole, inviteMember, isEdit, memberObj, updateMemberRole } = useCreateMember()

const enableButton = computed(() => {
  return !!(selected_roles.value?.length)
})

fetchRoles()

onBeforeUnmount(() => clearObj())

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "text-[#716D6A]",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = false))
    }, [
      _createVNode(_unref(X), {
        size: 16,
        "stroke-width": 2.5
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        src: _imports_0,
        class: "w-[100px]",
        alt: ""
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_unref(isEdit) ? 'Update member role' : 'Send an invite to a new member'), 1),
        (!_unref(isEdit))
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Enter the email address of the member you want to invite and assign the roles they should have. "))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-[#344054]" }, "Email address", -1)),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(memberObj)?.member_email || 'N/A'), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-[#344054]" }, "Full name", -1)),
                _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(memberObj)?.member_info?.fname) + " " + _toDisplayString(_unref(memberObj)?.member_info?.lname), 1)
              ])
            ]))
      ]),
      _createElementVNode("form", {
        class: "flex flex-col gap-4",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_unref(isEdit) ? _unref(updateMemberRole)(partnerContext.value.partner.id) : _unref(inviteMember)(partnerContext.value.partner.id)), ["prevent"]))
      }, [
        (!_unref(isEdit))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _cache[5] || (_cache[5] = _createElementVNode("label", { class: "text-[#344054] text-sm" }, "Email address", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(email) ? (email).value = $event : null)),
                required: "",
                type: "text",
                class: "p-2 border border-[#D0D5DD] text-sm focus:outline-none rounded-lg"
              }, null, 512), [
                [
                  _vModelText,
                  _unref(email),
                  void 0,
                  { trim: true }
                ]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_12, [
          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-xs text-[#8D918F]" }, "ASSIGN ROLES", -1)),
          (_unref(loading_roles))
            ? (_openBlock(), _createBlock(skeleton, {
                key: 0,
                height: "150px",
                radius: "10px",
                class: "border-0"
              }))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(roles), (n) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: n?.id,
                  type: "button",
                  class: "flex items-start gap-2",
                  onClick: ($event: any) => (_unref(handleSelectRole)(n?.id))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["w-4 h-4 mt-1 rounded border border-[#B9BCC8] shrink-0 flex items-center justify-center", [_unref(selected_roles)?.includes(n?.id) ? 'bg-[#20E682] border-0' : 'bg-transparent']])
                  }, [
                    (_unref(selected_roles)?.includes(n?.id))
                      ? (_openBlock(), _createBlock(_unref(Check), {
                          key: 0,
                          size: 12,
                          class: "text-white"
                        }))
                      : _createCommentVNode("", true)
                  ], 2),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("p", _hoisted_15, _toDisplayString(n?.label || 'N/A'), 1),
                    _createElementVNode("p", _hoisted_16, _toDisplayString(n?.description || 'N/A'), 1)
                  ])
                ], 8, _hoisted_13))
              }), 128))
        ]),
        _createElementVNode("button", {
          class: "btn",
          type: "submit",
          disabled: !enableButton.value || _unref(loading)
        }, _toDisplayString(_unref(loading) ? 'processsing...' : (_unref(isEdit) ? 'Update' : 'Send Invite')), 9, _hoisted_17)
      ], 32)
    ])
  ]))
}
}

})