import { computed, ref } from "vue";
import { sendAnalytics } from "./analytics";

const user = ref(null as null|Record<string, any>)

const userRoles = computed(() => {
  return user.value?.roles.map((role:Record<string, any>) => role?.name)
})

export const useUser = () => {
  const setUser = (data: Record<string, any>) => {
    user.value = data
    sendAnalytics('Partner Logged in', {
      role: user.value?.role === 'partner_owner' ? 'super_admin' : 'admin'
    })
  }

  return { user, setUser, userRoles }
}
