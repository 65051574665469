import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/brand_green.svg'
import _imports_1 from '@/assets/images/backgroundGraphics.svg'


const _hoisted_1 = { class: "flex justify-center items-center flex-col relative container mx-auto min-h-screen space-y-5" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col justify-between space-y-12 md:w-5/12 lg:w-4/12 w-11/12 mx-auto px-4 py-8 z-10 bg-white rounded-lg max-w-xl lg:max-w-2xl"
}
const _hoisted_3 = { class: "flex justify-between items-center flex-col" }
const _hoisted_4 = { class: "mt-4 text-sh-grey-900 font-bold text-lg lg:text-3xl text-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "w-full space-x-2 mt-8 flex flex-grow"
}
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 1,
  class: "w-full flex flex-col space-x-2"
}
const _hoisted_10 = {
  key: 1,
  class: "text-sh-grey-900 font-bold text-lg text-center"
}

import {Partner} from "@/models/organisation.model";
import {ref, Ref, computed} from "vue";
import {extractErrorMessage} from "@/utils/helper";
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';

interface InvitePageData {
  partnerInvitations: Partial<Partner>[] | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MemberInvite',
  setup(__props) {

const toast = useToast()
const partnerInvitations = ref([]) as Ref<any[]>
const loadingPageData = ref(false);
const processingDecision = ref(false);

const inviteTitle = computed(() => {
  return (partnerInvitations.value?.length
    ? (partnerInvitations.value.length > 1
        ? 'Please make a decision on your pending invitations'
        : `Join <span class="text-sh-green-500">${partnerInvitations.value[0].company_name}</span> on Shuttlers`)
    : 'No partner invitations');
})

const fetchPartnerInvitationData = () => {
  // const user: UserData = this.$store.getters['auth/user'];
  axios.get(`v1/partner/invitations`)
    .then((response: any) => {
      partnerInvitations.value = response.data.data;
    }).catch((err: any) => {
      partnerInvitations.value = [{
        id: '',
        company_name: 'John doe limited',
      }]
      toast.error(extractErrorMessage(err), { position: 'top-right' })
    }).finally(() => {
      loadingPageData.value = false;
    });
}
const rejectInvitation = () => {
  const partner_account_sid = 1;
  processingDecision.value = true;
  axios.post(`v1/partners/${partner_account_sid}/member-invitations/reject`)
    .then((res: any) => {
      // TODO: handle redirection after confirming design workflow
    })
    .catch((err: any) => {
      toast.error(extractErrorMessage(err), { position: 'top-right' })
    })
    .finally(() => {
      processingDecision.value = false;
    });
}
const acceptInvitation = () => {
  const partner_account_sid = 1;
  processingDecision.value = true;
  axios.post(`v1/partners/${partner_account_sid}/member-invitations/reject`)
    .then((res: any) => {
      // TODO: handle redirection after confirming design workflow
    })
    .catch((err: any) => {
      toast.error(extractErrorMessage(err), { position: 'top-right' })
    })
    .finally(() => {
      processingDecision.value = false;
    });
}

fetchPartnerInvitationData()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (!loadingPageData.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_0,
            class: "h-8"
          }, null, -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, [
              _createElementVNode("span", { innerHTML: inviteTitle.value }, null, 8, _hoisted_5)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mt-4 text-grays-black-5 font-light text-sm md:text-base text-center" }, [
              _createElementVNode("span", null, "Accept the invitation to become a member")
            ], -1)),
            (partnerInvitations.value.length === 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("button", {
                    disabled: processingDecision.value,
                    class: _normalizeClass([processingDecision.value ? 'opacity-0.5' : '', "block cursor-pointer flex-1 rounded-lg border border-red-dark text-red-dark font-bold text-sm py-3 text-center"]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (rejectInvitation()))
                  }, "Reject", 10, _hoisted_7),
                  _createElementVNode("button", {
                    disabled: processingDecision.value,
                    class: _normalizeClass([processingDecision.value ? 'opacity-0.5' : '', "hover:bg-sh-green-600 block cursor-pointer flex-1 rounded-lg border border-transparent text-sh-grey-900 font-bold bg-sh-green-500 text-sm py-3 text-center"]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (acceptInvitation()))
                  }, "Accept", 10, _hoisted_8)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(partnerInvitations.value, (invitation, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "w-full"
                    }))
                  }), 128))
                ]))
          ])
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_10, "Loading pending invitations...")),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute bottom-0 hidden lg:flex absolute bottom-0 z-0" }, [
      _createElementVNode("img", {
        src: _imports_1,
        class: "w-auto"
      })
    ], -1))
  ]))
}
}

})