import { ref } from "vue"
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useCoreModal } from "@/composables/modals/modals";
import router from '@/router';

const toast = useToast()
const loading = ref(false)
const loading_data = ref(false)

const obj = {
  fname: ref(''),
  lname: ref(''),
  email: ref(''),
  phone: ref(''),
  country_code: ref(''),
  password: ref(''),
  c_password: ref(''),
  partnerName: ref(''),
  member_id: ref('')
}

const clearObj = () => {
  // obj.email.value = ''
  obj.fname.value = ''
  obj.lname.value = ''
  obj.phone.value = ''
  obj.country_code.value = ''
  obj.password.value = ''
  obj.c_password.value = ''
}

const logout = () => {
  router.push('/login')
  setTimeout(() => {
    router.go(0)
  }, 500);
}

const getDataFromCode = async (code:string) => {
  try {
    loading_data.value = true
    const res = await axios.get(`/v1/account_members/invite?code=${code}`)
    if (res?.status === 200) {
      obj.email.value = res.data?.member_email || ''
      obj.partnerName.value = res.data?.name || ''
      obj.member_id.value = res.data?.invite_id || ''
    }
  } catch (error:any) {
    toast.error(error?.response?.data.message || 'An error occured', { position: 'top-right' });
  } finally {
    loading_data.value = false
  }
}

export const useAcceptMemberInvite = () => {
  const acceptMemberInvite = async () => {
    try {
      loading.value = true
      const response = await axios.patch(`/v1/account_members/${obj.member_id.value}/accept`)
      if (response?.status === 200) {
        clearObj()
        useCoreModal().openAcceptMember()
      }
    } catch (error:any) {
      toast.error(error?.response?.data.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading.value = false
    }
  }
  const acceptInvite = async () => {
    loading.value = true
    try {
      const payload = {
        fname: obj.fname.value,
        lname: obj.lname.value,
        phone: obj.phone.value,
        email: obj.email.value,
        password: obj.password.value,
        country_code: obj.country_code.value
      }
      const res = await axios.post(`/v1/users`, payload)
      if (res?.status === 200 || res?.status === 201) {
        await acceptMemberInvite()
      }
    } catch (error:any) {
      if (error?.response?.data?.code === 'ItemAlreadyExist') {
        await acceptMemberInvite()
        return
      }
      toast.error(error?.response?.data.message || 'An error occured', { position: 'top-right' });
    } finally {
      loading.value = false
    }
  }

  return { loading, ...obj, clearObj, acceptInvite, getDataFromCode, loading_data, logout }
}
