import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/arrow.svg'
import _imports_1 from '@/assets/images/faqs/question_mark.svg'


const _hoisted_1 = { class: "space-y-12" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col space-y-2 relative"
}
const _hoisted_3 = {
  key: 0,
  class: "text-sm text-red-500"
}
const _hoisted_4 = {
  key: 1,
  class: "relative mt-2"
}
const _hoisted_5 = { class: "flex justify-center mt-3" }
const _hoisted_6 = { class: "text-center mt-6 text-xs" }
const _hoisted_7 = { class: "mt-1" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 2,
  class: "flex justify-center items-center space-x-2 text-sm font-medium"
}
const _hoisted_10 = { key: 3 }

import { ref, Ref, computed, watch } from 'vue';
import { setZohoUser } from '@/utils/zoho';
import { required } from '@vuelidate/validators';
import countryCodeEmoji from 'country-code-emoji';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/mobile';
import LoginMixin from "@/mixins/LoginMixin";
import VOtpInput from 'vue3-otp-input';
import CountdownTimer from "@/components/CountdownTimer.vue";
import {extractErrorMessage} from "@/utils/helper";
import useVuelidate from '@vuelidate/core';
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setPosthogIdentity } from '@/composables/analytics';
import InputPhone from '@/components/core/InputPhone.vue';

// mixins: [LoginMixin],s

export default /*@__PURE__*/_defineComponent({
  __name: 'PhoneLogin',
  props: {
    countries: {}
  },
  emits: ['otp-state-change', 'goToSignUp'],
  setup(__props: any, { emit: __emit }) {

const store = useStore()
const route = useRoute()
const toast = useToast()
const validations = {
  form: {
    phone: { required }
  }
}
const emit = __emit
const props = __props
const form = ref({
  phone: '',
  country: '',
  otpValue: ''
});
const isPhoneValid = ref(false);
const loginStep = ref(1);
const otpReferenceId = ref(null) as Ref<any>
const v$ = useVuelidate(validations, {form})
const loading = ref(false)

const countrySelectDisabled = computed(() => {
  return props.countries?.length <= 1
})

const canProceed = computed(() => {
  return loginStep.value === 1
    ? !v$.value.form.phone.$error && isPhoneValid.value
    : form.value.otpValue;
})

const setDefaultCountry = () => {
  const code = props.countries && props.countries.length ? (props.countries[0] as any).code : null;
  if (code) {
    form.value.country = code;
  }
}
const validatePhoneNumber = () => {
  isPhoneValid.value = isValidPhoneNumber(
    form.value.phone,
    form.value.country as CountryCode
  );
}
const countryCodeToEmoji = (code: string) => {
  return countryCodeEmoji(code);
}
const login = () => {
  if (form.value.otpValue) {
    const payload = {
      otpCode: form.value.otpValue,
      type: "user",
      referenceId: otpReferenceId.value,
    };
    loading.value = true;

    axios.patch(`v1/otp-login/${payload.referenceId}`, {otp_code: payload.otpCode, type: payload.type})
      .then(async (loginResponse) => {
        form.value.otpValue = '';
        form.value.phone = '';
        setZohoUser(loginResponse.data)
        await store.dispatch('auth/authSuccess', loginResponse.data);
        // window.$zoho.salesiq.reset();
        setPosthogIdentity(loginResponse?.data)
        const redirect: any = route.query.redirect;
        if (redirect) {
          router.push({path: redirect});
        } else {
          router.push({
            name: "dashboard",
            query: { ...route.query },
          });
        }
      })
      .catch((err) => {
        const errorMessage = extractErrorMessage(err, null, 'Oops! An error occurred, please try again.');
        toast.error(errorMessage, { position: 'top-right' });
      })
      .finally(() => (loading.value = false));
  } else {
    toast.error('Please enter a valid OTP', { position: 'top-right' });
  }
}
const proceed = () => {
  if (loginStep.value === 1) {
    if (isPhoneValid.value && !v$.value.form.phone.$invalid) {
      loginStep.value++;
      emit('otp-state-change', `Please enter the 4-digit code sent to your phone number <b>${form.value.phone}</b>`);
      initiateOtp();
    }
  } else {
    login();
  }
}
const backToStep1 = () => {
  loginStep.value = 1;
  emit('otp-state-change', null);
}
const handleOnChange = (data: string) => {
  if (data.length < 4) {
    form.value.otpValue = '';
  }
}
const handleOnComplete = (data: string) => {
  form.value.otpValue = data;
}
const initiateOtp = async () => {
  const payload = {
    phone: form.value.phone,
    country_code: form.value.country,
    type: "user",
  };
  const response = await axios.post(`v1/otp-login`, payload);
  otpReferenceId.value = response.data.reference_id;
}

watch(() => form.value.phone, (value, oldValue) => {
  validatePhoneNumber()
})

// watch(() => props.countries, (value, oldValue) => {
//   setDefaultCountry()
// })

// setDefaultCountry()

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    (loginStep.value === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { class: "text-grays-black-5 font-medium text-sm" }, "Phone number", -1)),
          _createVNode(InputPhone, {
            code: form.value.country,
            "onUpdate:code": _cache[0] || (_cache[0] = ($event: any) => ((form.value.country) = $event)),
            phone: form.value.phone,
            "onUpdate:phone": _cache[1] || (_cache[1] = ($event: any) => ((form.value.phone) = $event))
          }, null, 8, ["code", "phone"]),
          (form.value.phone?.length && !isPhoneValid.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Invalid phone number "))
            : _createCommentVNode("", true)
        ]))
      : (loginStep.value === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(VOtpInput), {
                ref: "otpInput",
                class: "justify-between space-x-2",
                "input-classes": "otp-input w-16 h-16 rounded bg-white focus:outline-none border border-grays-black-9 text-center ",
                separator: "",
                "num-inputs": 4,
                "should-auto-focus": true,
                "is-input-num": true,
                onOnChange: handleOnChange,
                onOnComplete: handleOnComplete
              }, null, 512)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "font-normal" }, "Didn't receive code?", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(CountdownTimer, {
                  value: 120,
                  onOnTimerRestart: initiateOtp
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (proceed())),
      disabled: !canProceed.value || loading.value,
      class: _normalizeClass([!canProceed.value || loading.value ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7' : 'bg-sh-green-500 font-medium', "border-none outline-none py-3 rounded-md mt-12 text-sm flex justify-center items-center w-full"])
    }, [
      _createTextVNode(_toDisplayString(loginStep.value === 1 ? 'Request OTP' : 'Login') + " ", 1),
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        class: "ml-2",
        src: _imports_0
      }, null, -1))
    ], 10, _hoisted_8),
    (loginStep.value === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-grays-black-3" }, "Do you want to be a partner?", -1)),
          _createElementVNode("p", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('goToSignUp'))),
            class: "text-indigo-600 text-sm md:text-base"
          }, "Join now")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("p", {
            class: "text-center tex-sm font-medium text-indigo-600",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (backToStep1()))
          }, "Back")
        ])),
    _createVNode(_component_router_link, {
      to: "/faq",
      class: "w-fit mx-auto text-sm py-2 px-3 flex items-center gap-2 text-[#0DAC5C] rounded-lg font-medium"
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [
        _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1),
        _createTextVNode(" Go to FAQ ")
      ])),
      _: 1
    })
  ]))
}
}

})