import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "overflow-x-auto w-full" }

import PageLayout from '@/components/layout/PageLayout.vue';
import PageActionHeader from '@/components/PageActionHeader.vue';
import TabContainer from '@/components/tab/TabContainer.vue';
import TabItem from '@/components/tab/TabItem.vue';
import { useStore } from 'vuex';
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Settings',
  setup(__props) {

const store = useStore()
const partnerContext:any = computed(() => store.getters['auth/activeContext'])


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(PageLayout, { "page-title": "Settings" }, {
    actionsPane: _withCtx(() => [
      _createVNode(PageActionHeader, null, {
        breadcrumbs: _withCtx(() => [
          _createElementVNode("main", _hoisted_1, [
            _createVNode(TabContainer, null, {
              default: _withCtx(() => [
                _createVNode(TabItem, {
                  title: 'Profile Information',
                  to: { name: 'settings.edit.partner' }
                }),
                (partnerContext.value.onboardingState.address.partner_type === 'business')
                  ? (_openBlock(), _createBlock(TabItem, {
                      key: 0,
                      title: 'Company Information',
                      to: { name: 'settings.edit.partner.company' }
                    }))
                  : _createCommentVNode("", true),
                _createVNode(TabItem, {
                  title: 'Settlement Account',
                  to: { name: 'settings.edit.settlement.account' }
                }),
                _createVNode(TabItem, {
                  title: 'Members',
                  to: "/settings/members"
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("main", null, [
        _createVNode(_component_router_view)
      ])
    ]),
    _: 1
  }))
}
}

})