import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

import { modal } from '@/composables/modals/modals'

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalBase',
  setup(__props) {


const { stack, modals } = modal


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(stack), (id) => {
    return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(modals)[id]), {
      key: id,
      "props-modal": id
    }, null, 8, ["props-modal"]))
  }), 128))
}
}

})