<template>
    <div class="bg-white w-screen h-screen top-0 lef-0 fixed z-20 p-4 md:p-6 flex flex-col items-start gap-8">
        <button class="text-[#716D6A]" @click="showModal = false">
            <X :size="16" :stroke-width="2.5" />
        </button>
        <div class="flex flex-col gap-6 w-full max-w-[480px] mx-auto overflow-auto">
            <img src="@/assets/logo.png" class="w-[100px]" alt="">
            <div class="flex flex-col gap-1">
                <h3 class="text-[#000005] text-2xl font-bold">{{ isEdit ? 'Update member role' : 'Send an invite to a new member' }}</h3>
                <p v-if="!isEdit" class="text-sm text-[#737876]">
                    Enter the email address of the member you want to invite and assign the roles they should have.
                </p>
                <div v-else class="flex flex-col">
                    <div class="flex items-center gap-4 justify-between text-sm font-normal py-2 pb-4 border-b border-dashed border-[#EFF2F7]">
                        <p class="text-[#344054]">Email address</p>
                        <p class="text-[#09090F]">{{ memberObj?.member_email || 'N/A' }}</p>
                    </div>
                    <div class="flex items-center gap-4 justify-between text-sm font-normal py-4 border-b border-dashed border-[#EFF2F7]">
                        <p class="text-[#344054]">Full name</p>
                        <p class="text-[#09090F]">{{ memberObj?.member_info?.fname }} {{ memberObj?.member_info?.lname }}</p>
                    </div>
                </div>
            </div>

            <form class="flex flex-col gap-4" @submit.prevent="isEdit ? updateMemberRole(partnerContext.partner.id) : inviteMember(partnerContext.partner.id)">
                <div v-if="!isEdit" class="flex flex-col gap-1">
                    <label class="text-[#344054] text-sm">Email address</label>
                    <input v-model.trim="email" required type="text" class="p-2 border border-[#D0D5DD] text-sm focus:outline-none rounded-lg">
                </div>
                <div class="flex flex-col gap-4">
                    <p class="text-xs text-[#8D918F]">ASSIGN ROLES</p>
                    <!-- {{ selected_roles }} -->
                    <skeleton v-if="loading_roles" height="150px" radius="10px" class="border-0" />
                    <template v-else>
                        <button v-for="n in roles" :key="n?.id" type="button" class="flex items-start gap-2" @click="handleSelectRole(n?.id)">
                            <div class="w-4 h-4 mt-1 rounded border border-[#B9BCC8] shrink-0 flex items-center justify-center" :class="[selected_roles?.includes(n?.id) ? 'bg-[#20E682] border-0' : 'bg-transparent']">
                                <Check v-if="selected_roles?.includes(n?.id)" :size="12" class="text-white" />
                            </div>
                            <div class="flex flex-col text-left text-sm">
                                <p class="text-[#09090F] font-medium">{{ n?.label || 'N/A' }}</p>
                                <p class="text-[#595E5C] font-normal text-[13px]">{{ n?.description || 'N/A' }}</p>
                            </div>
                        </button>
                    </template>
                </div>

                <button class="btn" type="submit" :disabled="!enableButton || loading">
                    {{ loading ? 'processsing...' : (isEdit ? 'Update' : 'Send Invite') }}
                </button>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref } from 'vue';
import {Check, X} from 'lucide-vue-next'
import { useCreateMember } from '@/composables/backend/members';
import skeleton from '@/components/loader/skeleton.vue';
import { useStore } from 'vuex';

const store = useStore()
const partnerContext:any = computed(() => store.getters['auth/activeContext'])
const { showModal, fetchRoles, loading, loading_roles, roles, email, selected_roles, clearObj, handleSelectRole, inviteMember, isEdit, memberObj, updateMemberRole } = useCreateMember()

const enableButton = computed(() => {
  return !!(selected_roles.value?.length)
})

fetchRoles()

onBeforeUnmount(() => clearObj())
</script>
