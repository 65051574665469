import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/overallRatings.svg'
import _imports_1 from '@/assets/images/ratingHike.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-sh-grey-900" }
const _hoisted_3 = { class: "flex space-x-2 items-center" }
const _hoisted_4 = { class: "text-sh-purple-700 text-sm" }
const _hoisted_5 = { class: "text-green-500" }


import Spinner from '@/components/layout/Spinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Ratings',
  props: {
    count: {},
    rating: {},
    loading: { type: Boolean }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(`
      rounded-md
      ring-1 ring-gray-200
      bg-grays-white
      p-6
      space-y-5
      shadow-sm ${
      _ctx.loading ? '--loading' : ''
    }`
    )
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(Spinner)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[2] || (_cache[2] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-grays-black-4" }, "Overall ratings", -1)),
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.rating) + " / 10", 1),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "bg-grays-black-7 w-full h-0.5" }, null, -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
            _createElementVNode("span", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.count), 1),
              _cache[0] || (_cache[0] = _createTextVNode(" ratings in total"))
            ])
          ])
        ], 64))
  ], 2))
}
}

})