import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-xs font-medium"
}
const _hoisted_2 = { class: "text-active" }

import {ref, Ref, computed, onBeforeUnmount, onMounted} from "vue";

export interface Props {
  value?: number
  shouldStart?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CountdownTimer',
  props: {
    value: { default: 600 },
    shouldStart: { type: Boolean, default: true }
  },
  emits: ['on-timer-restart'],
  setup(__props: any, { emit: __emit }) {

const time = ref(10)
const timer = ref(0) as Ref<any>
const emit = __emit
const props = __props

const onClickResendCode = () => {
  time.value = props.value;
  timer.value = setInterval(decrement, 1000);
  emit('on-timer-restart')
  // this.$emit("on-timer-restart")
}

const decrement = () => {
  if (time.value > 0) {
    time.value--;
    return;
  }

  clearInterval(timer.value);
}

const timeLeft = computed(() => {
  return `${minutes.value}:${seconds.value}`;
})
const minutes = computed(() => {
  return String(Math.floor(time.value / 60)).padStart(2, "0");
})
const seconds = computed(() => {
  return String(time.value % 60).padStart(2, "0");
})
const isCountdownComplete = computed(() => {
  return time.value === 0;
})

onBeforeUnmount(() => {
  clearInterval(timer.value)
})

onMounted(() => {
  time.value = props.value
  timer.value = setInterval(decrement, 1000);
})

return (_ctx: any,_cache: any) => {
  return (isCountdownComplete.value)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "text-xs text-active font-medium cursor-pointer",
        onClick: onClickResendCode
      }, "Resend code"))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _cache[0] || (_cache[0] = _createTextVNode("Resend code in ")),
        _createElementVNode("b", _hoisted_2, _toDisplayString(timeLeft.value), 1)
      ]))
}
}

})