import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



export default /*@__PURE__*/_defineComponent({
  __name: 'skeleton',
  props: {
  width: {
    default: '100%',
    type: String
  },
  height: {
    default: '100%',
    type: String
  },
  radius: {
    default: '0px',
    type: String
  }
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "pulsate border border-gray-400",
    style: _normalizeStyle({ width: __props.width, height: __props.height, 'border-radius': __props.radius })
  }, null, 4))
}
}

})