import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/brand_green.svg'
import _imports_1 from '@/assets/sh-modal/success.svg'
import _imports_2 from '@/assets/images/backgroundGraphics.svg'


const _hoisted_1 = { class: "flex justify-center items-center flex-col relative container mx-auto min-h-screen space-y-5" }
const _hoisted_2 = { class: "flex flex-col justify-between space-y-12 md:w-5/12 lg:w-4/12 w-11/12 mx-auto px-4 py-8 z-10 bg-white rounded-lg max-w-xl lg:max-w-2xl" }
const _hoisted_3 = { class: "flex justify-between items-center flex-col" }
const _hoisted_4 = { class: "mt-12 text-sh-grey-900 font-bold text-lg lg:text-2xl text-center" }
const _hoisted_5 = { class: "mt-4 text-grays-black-5 font-light text-sm md:text-base text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        class: "h-10"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          src: _imports_1,
          class: "h-24"
        }, null, -1)),
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.options.title), 1),
        _createElementVNode("p", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.options.description), 1)
        ]),
        _createElementVNode("button", {
          class: "hover:bg-sh-green-600 block cursor-pointer mt-16 rounded-lg border border-transparent text-sh-grey-900 font-bold bg-sh-green-500 text-sm w-full py-3 text-center",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionClicked()))
        }, _toDisplayString(_ctx.options.buttonText), 1)
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute bottom-0 hidden lg:flex absolute bottom-0 z-0" }, [
      _createElementVNode("img", {
        src: _imports_2,
        class: "w-auto"
      })
    ], -1))
  ]))
}