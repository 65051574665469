import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center w-full gap-4 -mt-4" }

import { useConfirmationModal } from '@/composables/confirmation'
import {ref, computed} from 'vue'
import Modal from '@/components/core/modal/Modal.vue';
import { useCoreModal } from '@/composables/modals/modals';


export default /*@__PURE__*/_defineComponent({
  __name: 'acceptMember',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    modal: "$atts.modal",
    title: "",
    class: "!text-sm",
    "auto-close": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("svg", {
          width: "96",
          height: "96",
          viewBox: "0 0 96 96",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("g", { filter: "url(#filter0_d_3821_29448)" }, [
            _createElementVNode("rect", {
              x: "16",
              y: "8",
              width: "64",
              height: "64",
              rx: "16.3404",
              fill: "#FAFAFA"
            }),
            _createElementVNode("rect", {
              x: "16.5",
              y: "8.5",
              width: "63",
              height: "63",
              rx: "15.8404",
              stroke: "#E0E6ED"
            }),
            _createElementVNode("path", {
              d: "M42.2491 64.5105L63.0552 50.3145C65.547 48.6163 67.0914 46.0963 67.5746 43.4012C68.0673 40.6967 67.4987 37.817 65.7555 35.3895C63.0836 31.6698 58.4126 30.1099 54.1397 31.1251C53.0313 31.3927 51.9512 31.8265 50.9373 32.4451C50.7669 32.5466 50.6056 32.6482 50.4352 32.7589L42.4291 38.2232C42.4197 38.2323 42.4197 38.2417 42.4197 38.2509C42.2965 38.6847 42.0409 39.0815 41.6427 39.3584C41.6333 39.3769 41.6049 39.3861 41.5859 39.4046C40.6763 40.0324 39.4258 39.857 38.7246 39.0261C38.7056 38.9985 38.6868 38.9799 38.6678 38.9614C38.6488 38.9337 38.6394 38.9152 38.6204 38.8967C38.6014 38.869 38.592 38.8505 38.573 38.8228C38.5636 38.8043 38.5445 38.7766 38.5352 38.7581C37.9666 37.8351 38.2319 36.626 39.1415 36.0074C39.5678 35.712 40.0605 35.6013 40.5437 35.6383L53.7606 26.6204V15.4893L32.9449 29.6852C30.4625 31.3835 28.9183 33.9034 28.4256 36.5986C27.9328 39.3031 28.5014 42.1828 30.2446 44.6103C32.9259 48.33 37.5969 49.8899 41.87 48.8747C42.9785 48.6071 44.0585 48.1733 45.063 47.5547C45.2334 47.4532 45.4041 47.3516 45.5651 47.2409L53.5806 41.7766C53.5806 41.7674 53.5806 41.7581 53.59 41.7489C53.7036 41.3151 53.9689 40.9275 54.3574 40.6414C54.3764 40.6229 54.3952 40.6137 54.4142 40.5952C55.3332 39.9674 56.5839 40.1428 57.2755 40.9737C57.2945 41.0013 57.3133 41.0199 57.3323 41.0384C57.3513 41.066 57.3607 41.0846 57.3797 41.1031C57.3987 41.1308 57.4081 41.1493 57.4272 41.177C57.4462 41.1955 57.4556 41.2231 57.4746 41.2417C58.0335 42.1647 57.7779 43.3646 56.8588 43.9923C56.4325 44.2784 55.9398 44.3985 55.4661 44.3615L42.2491 53.3792V64.5105Z",
              fill: "#4848ED"
            })
          ]),
          _createElementVNode("defs", null, [
            _createElementVNode("filter", {
              id: "filter0_d_3821_29448",
              x: "0",
              y: "0",
              width: "96",
              height: "96",
              filterUnits: "userSpaceOnUse",
              "color-interpolation-filters": "sRGB"
            }, [
              _createElementVNode("feFlood", {
                "flood-opacity": "0",
                result: "BackgroundImageFix"
              }),
              _createElementVNode("feColorMatrix", {
                in: "SourceAlpha",
                type: "matrix",
                values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                result: "hardAlpha"
              }),
              _createElementVNode("feOffset", { dy: "8" }),
              _createElementVNode("feGaussianBlur", { stdDeviation: "8" }),
              _createElementVNode("feComposite", {
                in2: "hardAlpha",
                operator: "out"
              }),
              _createElementVNode("feColorMatrix", {
                type: "matrix",
                values: "0 0 0 0 0.135632 0 0 0 0 0.135632 0 0 0 0 0.445722 0 0 0 0.15 0"
              }),
              _createElementVNode("feBlend", {
                mode: "normal",
                in2: "BackgroundImageFix",
                result: "effect1_dropShadow_3821_29448"
              }),
              _createElementVNode("feBlend", {
                mode: "normal",
                in: "SourceGraphic",
                in2: "effect1_dropShadow_3821_29448",
                result: "shape"
              })
            ])
          ])
        ], -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col gap-2 text-center" }, [
          _createElementVNode("p", { class: "text-lg text-[#09090F] font-medium" }, "Download the Shuttlers partner mobile app"),
          _createElementVNode("p", { class: "text-base text-[#737876]" }, "Enjoy a smoother and more efficient experience with the partner mobile app.")
        ], -1)),
        _createElementVNode("button", {
          type: "button",
          class: "h-[40px] mt-6 w-full rounded-lg bg-[#20E682] text-black text-sm font-medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(useCoreModal)().closeAcceptMember()))
        }, " Dismiss ")
      ])
    ]),
    _: 1
  }))
}
}

})