import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/pointer.svg'
import _imports_1 from '@/assets/images/arrow.svg'


const _hoisted_1 = { class: "space-y-5" }
const _hoisted_2 = { class: "flex justify-center items-center space-x-4" }
const _hoisted_3 = { class: "flex items-center space-x-2" }
const _hoisted_4 = { class: "text-grays-black font-medium text-base lg:text-xl" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "lg:flex justify-between space-y-3 lg:space-y-0 lg:space-x-10 items-center" }
const _hoisted_7 = { class: "space-y-2 w-full" }
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_10 = { class: "space-y-2 w-full" }
const _hoisted_11 = { class: "text-xs font-medium text-grays-black-5" }
const _hoisted_12 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_13 = { class: "flex justify-start space-x-10 items-start pt-5" }
const _hoisted_14 = { class: "space-y-2 w-full lg:w-6/12 md:pr-5" }
const _hoisted_15 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "flex justify-start flex-col space-y-5 items-start" }
const _hoisted_18 = { class: "space-y-2 w-full" }
const _hoisted_19 = {
  key: 0,
  class: "text-sm font-light text-red-500"
}
const _hoisted_20 = { class: "space-y-2 w-full relative" }
const _hoisted_21 = { class: "flex justify-end" }
const _hoisted_22 = {
  key: 0,
  class: "flex items-center space-x-5"
}
const _hoisted_23 = ["disabled"]
const _hoisted_24 = {
  key: 1,
  class: "flex space-x-5"
}
const _hoisted_25 = ["disabled"]
const _hoisted_26 = {
  key: 0,
  class: "ml-2",
  src: _imports_1
}

import { ref, Ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Datepicker from 'vue3-datepicker';
import ImageUpload from '@/components/ImageUpload.vue';
import { required, minLength } from '@vuelidate/validators';
import { extractErrorMessage } from '@/utils/helper';
import useVuelidate from '@vuelidate/core';
import { UserData } from '@/models/user-session.model';
import Spinner from '@/components/layout/Spinner.vue';
import { PartnerOrganization, OnboardingState } from '@/models/organisation.model';
import router from '@/router';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';
import { useRoute } from 'vue-router';
import ordinaryAutoComplete from '@/components/core/ordinaryAutoComplete.vue'
import {formatDate} from '@/composables/utils'
import { resetPosthogIdentity, sendAnalytics } from '@/composables/analytics';
import moment from 'moment';


export default /*@__PURE__*/_defineComponent({
  __name: 'KycInformation',
  setup(__props) {

const route = useRoute()
const toast = useToast()
const store = useStore()
const identificationOptions = [
  {
    key: 'nin',
    label: 'NIN',
    desc: 'National Identification Number'
    // maxLength: 11
  },
  {
    key: 'bvn',
    label: 'BVN',
    desc: 'Bank Verification Number'
    // maxLength: 11
  }
  /*        {
    key: 'drivers-license',
    label: 'Drivers License',
    desc: 'Drivers License'
  },
  {
    key: 'passport',
    label: 'Passport',
    desc: 'Passport'
  },
  {
    key: 'voters-card',
    label: 'Voters card',
    desc: 'Voters Card'
  } */
]
const identityForm = ref({
  user: {
    document_owner_id: null as any,
    partner_type: null as any
  },
  document: {
    document_id: null as any,
    type: null as any,
    dob: '',
    fname: null as any,
    lname: null as any
  }
})
const addressForm = ref({
  user: {
    document_owner_id: null as any,
    partner_type: null as any
  },
  document: {
    full_address: null as any,
    files: [] as any[]
  }
});
const loading = ref(false);
const identityDocumentLength = ref(11);
const activeView = ref(0);
const file = ref('') as Ref<any>
const fileData = ref(null) as Ref<any>
const selectedIdentityDoc = ref(null) as Ref<any>
const addressProgress = ref(false);
const validations = {
  identityForm: {
    user: {
      document_owner_id: { required },
      partner_type: { required }
    },
    document: {
      document_id: {
        required,
        minLength: minLength(identityDocumentLength.value)
      },
      type: { required },
      dob: { required },
      fname: { required },
      lname: { required }
    }
  },
  addressForm: {
    user: {
      document_owner_id: { required },
      partner_type: { required }
    },
    document: {
      full_address: { required }
    }
  }
}
const v$ = useVuelidate(validations, {identityForm, addressForm})
const avatar = ref(null) as Ref<any>
const uploadingAvatar = ref(false)

const user:any = computed(() => store.getters['auth/user'])
const contextOrganization:any = computed(() => store.getters['auth/activeContext'])
const getDocumentLabel = computed(() => {
  if (activeView.value === 0) {
    return identityForm.value.document.type
      ? selectedIdentityDoc.value?.desc
      : 'Document ID';
  }
  return '';
})

onMounted(async () => {
  await store.dispatch('auth/refreshActiveContext', user.value.id);
})

const setFormDefaults = () => {
  const _user: UserData = user.value;
  const existingBusinessMode = contextOrganization.value?.partner?.mode;
  identityForm.value.user.document_owner_id = _user.id;
  identityForm.value.user.partner_type = existingBusinessMode || (route.query.type === 'individual' ? 'individual' : 'business');
  identityForm.value.document.fname = _user.fname;
  identityForm.value.document.lname = _user.lname;

  addressForm.value.user.document_owner_id = _user.id;
  addressForm.value.user.partner_type = existingBusinessMode || (route.query.type === 'individual' ? 'individual' : 'business');
}
const previous = () => {
  activeView.value -= 1;
}
const uploadFile = () => {
  file.value = avatar.value.files[0];
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    uploadingAvatar.value = true;
  });
  reader.readAsDataURL(file.value);
}
const handleIdentityChange = () => {
  selectedIdentityDoc.value = identificationOptions.find(
    (obj: any) => obj.key === identityForm.value.document.type
  );
}
const saveIdentityForm = async () => {
  v$.value.identityForm.$touch();
  if (loading.value || v$.value.identityForm.$errors.length) {
    return;
  }
  try {
    loading.value = true;
    await store.dispatch('auth/refreshActiveContext', user.value.id);
    await axios.post(
      `/v1/partners/${contextOrganization.value.account_sid}/identity-verification`,
      identityForm.value
    );
    await store.dispatch('auth/setActiveContext', {
      onboardingState: {
        ...store.getters['auth/activeContext'].onboardingState,
        identity: 'completed'
      } as OnboardingState
    } as PartnerOrganization);
    activeView.value += 1;
    sendAnalytics('Partner Entered Identity KYC Information', {
      means_of_identification: identityForm.value?.document?.type,
      date_of_birth: moment(identityForm.value?.document?.dob).format('ll')
    })
  } catch (err) {
    const errorMessage = extractErrorMessage(
      err,
      null,
      'Oops! An error occurred, please try again.'
    );
    toast.error(errorMessage, { position: 'top-right' });
  } finally {
    loading.value = false;
  }
}
const saveAddressForm = async () => {
  v$.value.addressForm.$touch();
  if (loading.value || v$.value.addressForm.$errors.length) {
    return;
  }
  if (!file.value) {
    toast.error('Kindly select a file', { position: 'top-right' });
    return;
  }
  try {
    loading.value = true;
    const formData = new FormData();
    formData.append('file', file.value);
    const response = await axios.post(
      `/v1/upload/identity/files`,
      formData
    );
    if (response.data?.files?.length) {
      addressForm.value.document.files = [response.data.files[0].Location];
    }
    await store.dispatch('auth/refreshActiveContext', user.value.id);
    const verifyResponse = await axios.post(
      `/v1/partners/${contextOrganization.value.account_sid}/address-verification`,
      addressForm.value
    );
    if (verifyResponse.status === 200) {
      await store.dispatch('auth/setActiveContext', {
        onboardingState: {
          ...store.getters['auth/activeContext'].onboardingState,
          address: 'completed'
        } as OnboardingState
      } as PartnerOrganization);
      sendAnalytics('Partner Entered Address KYC Information')
      await router.push({ name: 'CitySelection' });
    }
  } catch (err) {
    const errorMessage = extractErrorMessage(
      err,
      null,
      'Oops! An error occurred, please try again.'
    );
    toast.error(errorMessage, { position: 'top-right' });
  } finally {
    loading.value = false;
  }
}
const setPageState = () => {
  if (!contextOrganization.value) {
    router.push({ name: 'PartnerSignUp' });
    return;
  }
  const identityFormStatus =
    contextOrganization.value.onboardingState?.identity;
  if (contextOrganization.value && identityFormStatus === 'completed') {
    activeView.value = 1;
    addressProgress.value = true;
  }
}
const selectFile = ($event: File) => {
  file.value = $event;
}
const removeFile = () => {
  file.value = '';
}
const logout = () => {
  resetPosthogIdentity()
  // window.$zoho.salesiq.reset();
  localStorage.clear();
  router.push('/login');
  router.go(0);
}

setPageState();
setFormDefaults();

return (_ctx: any,_cache: any) => {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: _normalizeClass([activeView.value === 0 ? 'text-grays-black-2' : 'text-grays-black-6', "text-sm"])
      }, " Identity verification ", 2),
      _cache[9] || (_cache[9] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("p", {
        class: _normalizeClass([activeView.value === 1 ? 'text-grays-black-2' : 'text-grays-black-6', "text-sm"])
      }, " Address verification ", 2)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "rounded-full p-1 border-2 border-indigo-500" }, null, -1)),
        _createElementVNode("h1", _hoisted_4, _toDisplayString(activeView.value === 0 ? 'Identity verification' : 'Address verification'), 1)
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("small", { class: "text-grays-black-3" }, "These information will help us know you more", -1))
    ]),
    (activeView.value === 0)
      ? (_openBlock(), _createElementBlock("form", _hoisted_5, [
          _createElementVNode("section", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[13] || (_cache[13] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Means of Identification", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "text-sm border-none outline-none w-full rounded-md p-3 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-sh-grey-300",
                onChange: _cache[0] || (_cache[0] = ($event: any) => (handleIdentityChange())),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).identityForm.document.type.$model) = $event))
              }, [
                _cache[12] || (_cache[12] = _createElementVNode("option", {
                  value: "",
                  hidden: ""
                }, "Select a document type", -1)),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(identificationOptions, (identity, index) => {
                  return _createElementVNode("option", {
                    value: identity.key,
                    key: index
                  }, _toDisplayString(identity.label), 9, _hoisted_8)
                }), 64))
              ], 544), [
                [_vModelSelect, _unref(v$).identityForm.document.type.$model]
              ]),
              (
              _unref(v$).identityForm.document.type.$dirty &&
              _unref(v$).identityForm.document.type.required.$invalid
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, " This field is required "))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", _hoisted_11, _toDisplayString(getDocumentLabel.value), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                pattern: "[a-zA-Z0-9]+",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).identityForm.document.document_id.$model) = $event)),
                class: "text-xs border-none outline-none w-full rounded-md p-3 placeholder-gray-500 placeholder-opacity-25 ring-1 ring-gray-300",
                placeholder: "Enter document number"
              }, null, 512), [
                [_vModelText, _unref(v$).identityForm.document.document_id.$model]
              ]),
              (
              _unref(v$).identityForm?.document?.document_id?.$dirty &&
              _unref(v$).identityForm?.document?.document_id?.required?.$invalid
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, " This field is required "))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("section", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[14] || (_cache[14] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Date Of Birth", -1)),
              _createVNode(_component_v_date_picker, {
                modelValue: (_unref(v$).identityForm.document.dob as any).$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (((_unref(v$).identityForm.document.dob as any).$model) = $event))
              }, {
                default: _withCtx(({ inputEvents }) => [
                  _createElementVNode("div", _mergeProps(_toHandlers(inputEvents, true), {
                    class: ["w-full ring-1 ring-gray-300 rounded-md outline-none text-xs py-3 px-4", [(_unref(v$).identityForm.document.dob as any).$model ? '' : 'text-gray-500/25']]
                  }), _toDisplayString((_unref(v$).identityForm.document.dob as any).$model ? _unref(formatDate)((_unref(v$).identityForm.document.dob as any).$model) : 'Choose a date'), 17)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (
              _unref(v$).identityForm.document.dob.$dirty &&
              _unref(v$).identityForm.document.dob.required.$invalid
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, " This field is required "))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (activeView.value === 1)
      ? (_openBlock(), _createElementBlock("form", _hoisted_16, [
          _createElementVNode("section", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[15] || (_cache[15] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Address", -1)),
              _createVNode(ordinaryAutoComplete, {
                inputValue: _unref(v$).addressForm.document.full_address.$model,
                onAutoCompleteAddress: _cache[4] || (_cache[4] = (val) => _unref(v$).addressForm.document.full_address.$model = val)
              }, null, 8, ["inputValue"]),
              (
              _unref(v$).addressForm.document.full_address.$dirty &&
              _unref(v$).addressForm.document.full_address.required.$invalid
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_19, " Please provide a valid address "))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[16] || (_cache[16] = _createElementVNode("label", { class: "text-xs font-medium text-grays-black-5" }, "Upload document (Electricity bill, Waste bill, water bill etc)", -1)),
              _createVNode(ImageUpload, {
                onFileSelected: _cache[5] || (_cache[5] = ($event: any) => (selectFile($event))),
                onFileRemoved: _cache[6] || (_cache[6] = ($event: any) => (removeFile()))
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_21, [
      (activeView.value === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("button", {
              class: "rounded-md w-1/2 flex justify-center items-center p-3 px-5 text-sm text-grays-black-5 ring-1 ring-gray-400 font-medium",
              onClick: logout
            }, " Continue Later "),
            _createElementVNode("button", {
              class: _normalizeClass(["rounded-md w-32 flex justify-center items-center p-3 px-5 text-sm", 
            _unref(v$).identityForm.$invalid || loading.value
              ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7'
              : 'bg-sh-green-500 font-medium'
          ]),
              disabled: _unref(v$).identityForm.$invalid || loading.value,
              onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (saveIdentityForm()), ["prevent"]))
            }, [
              _createTextVNode(_toDisplayString(loading.value ? 'Saving' : 'Next') + " ", 1),
              _cache[17] || (_cache[17] = _createElementVNode("img", {
                class: "ml-2",
                src: _imports_1
              }, null, -1))
            ], 10, _hoisted_23)
          ]))
        : _createCommentVNode("", true),
      (activeView.value === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createElementVNode("button", {
              class: "rounded-md w-1/2 flex justify-center items-center p-3 px-5 text-sm text-grays-black-5 ring-1 ring-gray-400 font-medium",
              onClick: logout
            }, " Continue Later "),
            _createElementVNode("button", {
              class: _normalizeClass(["rounded-md w-32 flex justify-center items-center p-3 px-5 text-sm font-medium", 
            _unref(v$).addressForm.$invalid || loading.value
              ? 'cursor-not-allowed text-grays-black-5 bg-grays-black-7'
              : 'bg-sh-green-500 font-medium'
          ]),
              disabled: _unref(v$).addressForm.$invalid || loading.value,
              onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (saveAddressForm()), ["prevent"]))
            }, [
              _createTextVNode(_toDisplayString(!loading.value ? 'Save' : '') + " ", 1),
              (!loading.value)
                ? (_openBlock(), _createElementBlock("img", _hoisted_26))
                : _createCommentVNode("", true),
              (loading.value)
                ? (_openBlock(), _createBlock(Spinner, { key: 1 }))
                : _createCommentVNode("", true)
            ], 10, _hoisted_25)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})