import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo2.svg'


const _hoisted_1 = { class: "flex flex-col h-screen gap-4 p-5 justify-center items-center w-screen" }

import { useSignInUserExternally } from '@/composables/auth/external'
import { useRoute, useRouter } from "vue-router";
import { onMounted } from 'vue'
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'redirect',
  setup(__props) {

const { query } = useRoute()
const router = useRouter()
const store = useStore()

const { sign_user_in } = useSignInUserExternally()

onMounted(async () => {
  const res = await sign_user_in((query.token as string).replace(/"/g, '').trim(), query.path as string)
  console.log(res);
  if (res) {
    await store.dispatch('auth/authSuccess', res);
    console.log('help me');
    router.push(query.path as string);
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "logo",
      class: "w-96"
    }, null, -1)
  ])))
}
}

})