import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "home flex flex-row" }
const _hoisted_2 = { class: "w-full pb-[80px]" }
const _hoisted_3 = {
  key: 0,
  class: "fixed top-0 left-0 w-full h-full bg-white z-20 flex gap-4 justify-between"
}

import {ref, watch} from "vue";
import Sidebar from "./Sidebar.vue";
import router from "@/router";
import { useSidebar } from "@/composables/sidebar";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const route = useRoute()
const { active, closeSidebar } = useSidebar()
const activeSection = ref("View All")

const changeNavigation = (_activeSection: string) => {
  activeSection.value = _activeSection;
  router.push(`/${_activeSection.toLowerCase()}`);
}

watch(route, () => {
  closeSidebar()
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Sidebar, {
        class: "hidden md:flex",
        onSideBarNavigationClicked: changeNavigation
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ]),
    (_unref(active))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Sidebar, {
            class: "max-w-[230px]",
            onSideBarNavigationClicked: changeNavigation
          }),
          _createElementVNode("div", {
            class: "flex-grow bg-[#2b29291b]",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(closeSidebar) && _unref(closeSidebar)(...args)))
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})