import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }

import { extractErrorMessage } from '@/utils/helper';
import { ref, computed } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import moment from 'moment';
import { useStore } from 'vuex';
import {axiosInstance as axios} from '@/plugins/axios';
import {useToast} from 'vue-toast-notification';


export default /*@__PURE__*/_defineComponent({
  __name: 'Chart',
  setup(__props) {

const store = useStore()
const toast = useToast();
const apexchart = VueApexCharts
const loading = ref(false)
const series = ref([
  {
    name: 'trips',
    data: [],
    color: '#000000'
  }
])
const chartOptions = ref({
  chart: {
    height: 350,
    type: 'bar',
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: 'top' // top, center, bottom
      }
    }
  },
  dropShadow: {
    enabled: true,
    top: 0,
    left: 0,
    blur: 3,
    opacity: 0.5
  },
  theme: {
    monochrome: {
      enabled: true,
      shadeTo: 'light',
      shadeIntensity: 0.65
    }
  },

  grid: {
    yaxis: {
      lines: {
        show: false,
        offsetX: 0,
        offsetY: 0
      }
    }
  },
  dataLabels: {
    enabled: false,
    formatter: function (val: number) {
      return val + '%';
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ['#304758']
    }
  },

  xaxis: {
    categories: [],
    position: 'right'
  }
})
const getBarChartTripsData = async () => {
  loading.value = true;
  try {
    const response =
      (await axios.get(
        `/v1/partners/${partnerContext.value.partner.id}/trips/stats`
      )) || [];
    const numberOfTripsPerDay = response.data.map(
      (item: { total: any }) => {
        return item.total;
      }
    );
    const tripDays = response.data.map((item: any) => {
      return moment(item.date).format('MMM Do YY');
    });
    chartOptions.value = {
      ...chartOptions.value,
      ...{
        xaxis: {
          categories: tripDays as any,
          position: 'right'
        }
      }
    };
    series.value[0].data = numberOfTripsPerDay as any;
  } catch (error) {
    const errorMessage = extractErrorMessage(
      error,
      null,
      'An error occured while fetching your trips history'
    );
    toast.warning(errorMessage, { position: 'top-right' });
  } finally {
    loading.value = false;
  }
}

const partnerContext:any = computed(() => store.getters['auth/activeContext'])

getBarChartTripsData()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex justify-between items-center" }, [
      _createElementVNode("h1", { class: "text-gray-400 font-medium" }, "Completed trips this month")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(apexchart), {
        class: "apexcharts-bar-area w-full overflow-x-auto",
        type: "bar",
        height: "350",
        options: chartOptions.value,
        series: series.value,
        "on-load": loading.value
      }, null, 8, ["options", "series", "on-load"])
    ])
  ]))
}
}

})