<template>
	<div class="relative">
		<input
			v-bind="$attrs"
			id="phone"
			v-model="phone_number"
			name="phone"
			autocomplete="off"
			:placeholder="placeholder"
			type="number"
			class="input-field w-full !pl-[115px] !outline-none"
			:disabled="disabled"
			required
			@input="handleInput"
		>
		<!-- <icon v-if="phone_number && !disabled" name="close-fill" class="w-4 absolute top-[58%] right-4 cursor-pointer" @click="clearInput" /> -->

		<div ref="target" class="flex flex-col absolute top-1/2 -translate-y-1/2 z-20">
			<DropdownMenuRoot v-slot="{ open }">
				<DropdownMenuTrigger as="button" :disabled="disabled" class="cursor-pointer flex items-center gap-2 p-1.5 px-2 py-0 bg-transparent border-r border-neut3 focus:outline-none">
					<span class="text-xl">{{ selectedCountry.flag }}</span>
					<span class="truncate">{{ selectedCountry.dial_code }}</span>
					<ChevronDown :size="14" :class="['ml-[-4px] w-4 duration-300', open ? 'rotate-180' : '']" />
				</DropdownMenuTrigger>

				<DropdownMenuContent class="bg-white  border  z-20 px-8 py-6  absolute top-[2rem] -left-[60px] w-[325px] rounded-lg overflow-hidden max-h-96 min-h-28">
					<section class="relative w-full flex flex-col gap-6 text-sm ">
						<input
							v-model="searchQuery"
							type="text"
							placeholder="Search country"
							class="p-2 border-b border-gray-300 !outline-none absolute top-0 w-full"
						>
						<div class="flex flex-col gap-6 text-sm mt-14 overflow-y-auto md:max-h-96 max-h-40 hide-scrollbar">
							<DropdownMenuItem v-for="country in filteredCountries" :key="country.code" class="flex items-center gap-3 !outline-none" @select="onDropdownClick(country)">
								<span class="text-xl">{{ country.flag }}</span>
								<p class="cursor-pointer flex items-center text-base">
									{{ country.name }}
								</p>
								<span class="ml-auto text-neut5">{{ country.dial_code }}</span>
							</DropdownMenuItem>
						</div>
					</section>
				</DropdownMenuContent>
			</DropdownMenuRoot>
		</div>
	</div>
</template>
<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted, computed } from 'vue'
import { DropdownMenuRoot, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from 'radix-vue'
import countries from '@/helper/countries'
import { ChevronDown } from 'lucide-vue-next'

interface Country {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
}

const selectedCountry = ref<Country>({ name: 'Nigeria', flag: '🇳🇬', code: 'NG', dial_code: '+234' })
const searchQuery = ref('')
const phone_number = ref('')

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Enter Phone number'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  code: {
    type: String,
    default: ''
  },
  phone: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:code', 'update:phone'])

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement
  //   const phone = `${dial_code.value}${Number(target.value)}`
  const phone = target.value
  emit('update:phone', phone)
}

const clearInput = () => {
  phone_number.value = ''
  emit('update:phone', '')
}

const onDropdownClick = (country: Country) => {
  selectedCountry.value = country
  emit('update:code', selectedCountry.value?.code)
}

const filteredCountries = computed(() => {
  return countries.filter((country) => country.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
})

onMounted(() => {
  const x = props.code || 'NG'
  selectedCountry.value = countries.filter((el) => el?.code === x)?.[0]
  emit('update:code', selectedCountry.value?.code)
  phone_number.value = props.phone
})
</script>

<style scoped lang="scss">
.dropdown-content {
  background: #ffffff;
  box-shadow: 4px 4px 16px 5px rgba(27, 25, 36, 0.12);
  border-radius: 16px;
}
</style>
