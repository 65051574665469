import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/earningsIcon.svg'
import _imports_1 from '@/assets/images/earningsArrow.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-grays-white text-2xl font-bold" }


import Spinner from '@/components/layout/Spinner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Earnings',
  props: {
    loading: { type: Boolean },
    accruedEarnings: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(`rounded-md bg-sh-grey-900 shadow-sm p-6 space-y-5 ${
      _ctx.loading ? '--loading' : ''
    }`)
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(Spinner)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-grays-black-8" }, "Earnings", -1)),
          _createElementVNode("h1", _hoisted_2, "NGN " + _toDisplayString(Intl.NumberFormat('en-Us').format(_ctx.accruedEarnings || 0)), 1),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "bg-grays-black-7 w-full h-0.5" }, null, -1)),
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, {
              class: "flex items-center justify-between space-x-3 text-sm hover:underline hover:decoration-sh-green-500",
              to: { name: 'earnings.information' }
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", { class: "text-grays-black-7 text-sm hover:text-sh-green-500" }, "View earnings summary", -1),
                _createElementVNode("img", { src: _imports_1 }, null, -1)
              ])),
              _: 1
            })
          ])
        ], 64))
  ], 2))
}
}

})